<template>
  <div class="container-fluid my-3 py-3" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="container-fluid" v-show="dataLoaded">
    <div class="row mt-3">
      <div class="col-lg-12 mx-auto">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="channgelManagerAllignment">
                <div class="element">
                  <h4>Інтеграція з Poster</h4>
                </div>
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="poster.isActive"
                  />
                </div>
              </div>
              <div class="">
                <span
                  >Інтеграція з Poster дає змогу отримувати чеки про оплату
                  ресторану та зберігати їх у бронюванні для подальшого
                  розрахунку.
                  <br />
                  Після увімкнення інтеграції в бронюванні в розділі "Оплата"
                  з'явиться секція Poster</span
                >
                <br />
                Для підключення інтеграції перейдіть в адмін панель Poster, та
                здійсніть підключення застосунку bookmenow з розділу Застосунки
              </div>
            </div>
            <div
              class="row infoBlock mt-3"
              v-if="infoMessage"
              style="display: inline-block"
            >
              <div class="d-flex align-items-center gap-2">
                <span class="material-symbols-outlined"> info </span>
                <label class="text-md" style="color: #458466">
                  {{ infoMessage }}
                </label>
              </div>
            </div>
          </div>
        </div>
        <!--  Зберегти -->
        <div class="fixed-bottom">
          <div class="row">
            <div class="col-10"></div>
            <div class="col-lg-2">
              <div class="mx-4">
                <button
                  type="button"
                  class="block btn bg-gradient-primary"
                  @click="save"
                >
                  <SmallLoader v-if="isLoading" />
                  <span v-else> Зберегти</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--  Зберегти фiнiш-->
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/services/api";
import Spinner from "@/views/shared/Spinner.vue";
import SmallLoader from "@/views/shared/SmallLoader.vue";
export default {
  name: "channel-manager",
  async mounted() {
    this.poster = await api.partnerIntegration.show(this.name);
    this.showDevFeatures = !!localStorage.getItem("showDevFeatures");

    const { poster_url, code } = this.$route.query;
    if (code) {
      this.infoMessage =
        "Натисніть ЗБЕРЕГТИ, щоб авторизувати bookmenow в Poster та завершити налаштування інтеграції.";
    }

    if (poster_url) {
      this.infoMessage =
        "Натисніть ЗБЕРЕГТИ, щоб продовжити налаштування інтеграції з Poster.";
    }

    this.dataLoaded = true;
  },
  data() {
    return {
      name: "POSTer",
      poster: {
        isActive: false,
        customData: {},
      },
      infoMessage: "",
      showDevFeatures: false,
      dataLoaded: false,
      isLoading: false,
    };
  },
  components: {
    SmallLoader,
    Spinner,
  },
  methods: {
    async save() {
      this.isLoading = true;
      try {
        const query = this.$route.query;
        if (query.poster_url && query.account_number) {
          this.poster.isActive = true;

          await api.partnerIntegration.update(this.name, {
            "customData.account": query.poster_url,
            "customData.accountNumber": query.account_number,
          });
          const applicationId = this.poster.customData.applicationId;
          const redirectUri = encodeURIComponent(
            this.poster.customData.redirectUri,
          );

          this.$store.commit("addToast", {
            title: "Збережено",
          });

          window.location.href = `https://${query.poster_url}.joinposter.com/api/auth?application_id=${applicationId}&redirect_uri=${redirectUri}&response_type=code`;
          return;
        }

        if (query.code && query.account) {
          // Generate POSTer API token and upload information about tenant to their API
          const updatedPoster = await api.partnerIntegration.finishPOSTerSetup({
            account: query.account,
            code: query.code,
          });

          this.poster.isActive = updatedPoster.isActive;

          this.infoMessage = "";
          this.$store.commit("addToast", {
            title: "POSTer успішно інтегровано",
          });
          return;
        }

        this.poster = await api.partnerIntegration.update(this.name, {
          isActive: this.poster.isActive,
        });
        this.$store.commit("addToast", {
          title: "Збережено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
.block {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}
.container-fluid {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.element {
  margin-top: 8px;
  margin-right: 20px;
}
.channgelManagerAllignment {
  display: flex;
  align-items: center; /* Выравниваем элементы по центру по вертикали */
  justify-content: flex-start;
  padding-right: 16px;
}
.infoBlock {
  color: #458466;
  background-color: #deede3;
  border-radius: 8px;
  margin-bottom: 16px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 12px 12px 12px 12px;
}
</style>
