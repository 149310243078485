<template>
  <div class="container-fluid my-3 py-3" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="container-fluid" v-show="dataLoaded">
    <div class="row mb-5">
      <div class="col-lg-12 mt-lg-0">
        <div class="row mt-3">
          <div class="available">
            <div class="element">Доступність</div>
            <div class="form-check form-switch">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="service.isActive"
              />
            </div>
          </div>
        </div>
        <div class="card" id="basic-info">
          <div class="card-body pt-0 mt-3">
            <div class="row">
              <h6>Додаткова послуга</h6>
              <div class="col-lg-4 mt-2">
                <label class="text-md">Назва</label>
                <div class="input-group input-group-outline">
                  <input
                    type="text"
                    class="form-control"
                    v-model="service.name"
                    :disabled="prohibitChangingServiceName()"
                  />
                </div>
              </div>
              <div class="col-lg-2 mt-2">
                <label class="text-md">Ціна</label>
                <div class="input-group input-group-outline">
                  <input
                    type="tel"
                    placeholder="0 грн"
                    class="form-control"
                    v-model="service.price"
                  />
                </div>
              </div>
              <div
                class="col-lg-2 mt-2"
                v-if="
                  branch.paymentLiqPayEnabled || branch.paymentMonobankEnabled
                "
              >
                <label class="text-md">Передплата</label>
                <SelectApp
                  v-model="prepaymentPercentage"
                  :settings="{
                    options: prepaymentOptions,
                    trackBy: 'value',
                    label: 'label',
                    placeholder: '% від ціни',
                    closeOnSelect: true,
                    taggable: true,
                    multiple: false,
                  }"
                  @select="selectPrepaymentPercentage"
                />
              </div>
              <div class="col-lg-4 mt-2" v-if="optionsSelect?.length">
                <label class="text-md" v-if="company.crmMode !== 'hotel'"
                  >Оберіть майстрів</label
                >
                <label class="text-md" v-if="company.crmMode === 'hotel'"
                  >Оберіть номери</label
                ><br />
                <SelectApp
                  v-model="selectedStaffs"
                  :settings="{
                    options: optionsSelect,
                    trackBy: 'code',
                    label: 'name',
                    placeholder: 'Оберіть номери',
                    closeOnSelect: false,
                    taggable: true,
                    multiple: true,
                  }"
                  @select="onMultiselectSelect"
                  @remove="onMultiselectSelect"
                />
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-lg-4">
                <label class="text-md">Опис послуги</label>
                <div class="input-group input-group-outline">
                  <textarea
                    class="form-control"
                    style="height: 75px"
                    v-model="service.description"
                    ref="description"
                    rows="2"
                    @input="autoResizeTextarea"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-4 mt-2" v-if="company.crmMode === 'hotel'">
                <label class="text-md">Додаткові правила</label>
                <div class="form-check customCheckbox">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="service.allowForOnlineForm"
                    id="fcustomCheck1"
                  />
                  <label
                    class="custom-control-label customLabelCheckbox"
                    for="customCheck1"
                    >{{ getLabelColumnAllowedForOnline() }}
                  </label>
                </div>
                <div
                  class="form-check customCheckbox"
                  v-for="(v, k, i) in customRules"
                  v-bind:key="i"
                >
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="service[k]"
                    id="fcustomCheck1"
                  />
                  <label
                    class="custom-control-label customLabelCheckbox"
                    for="customCheck1"
                    >{{ v.description }}
                  </label>
                  <a
                    v-if="v.tooltip && v.tooltip.length"
                    href="javascript:"
                    class="mx-1"
                    data-bs-toggle="tooltip"
                    :data-bs-original-title="v.tooltip"
                  >
                    <i
                      class="material-icons text-secondary position-relative text-lg"
                      >info</i
                    >
                  </a>
                </div>
              </div>
            </div>

            <div
              class="row mt-2"
              v-if="company.crmMode === 'hotel' && service.isPricePerGuest"
            >
              <div
                class="col-lg-2"
                v-for="item in pricesPerGuest"
                v-bind:key="item"
              >
                <label class="text-md">Ціна за {{ item.guests }} гостя</label>
                <div class="input-group input-group-outline">
                  <input
                    type="tel"
                    placeholder="0 грн"
                    class="form-control"
                    v-model="item.price"
                  />
                </div>
              </div>
            </div>
            <hr />

            <!-- Додаткове правило старт -->
            <div v-if="company.crmMode === 'hotel' && service.isSystem">
              <template
                v-for="(rule, i) in service.additionalRules"
                v-bind:key="i"
              >
                <div class="card-body pt-0 mt-3">
                  <div class="row">
                    <div class="col-lg-4 mt-2">
                      <label class="text-md">Назва</label>
                      <div class="input-group input-group-outline">
                        <input
                          type="text"
                          class="form-control"
                          v-model="service.name"
                          disabled="true"
                        />
                      </div>
                    </div>

                    <div class="col-lg-2 mt-2">
                      <label class="text-md">Ціна</label>
                      <div class="input-group input-group-outline">
                        <input
                          type="tel"
                          placeholder="0 грн"
                          class="form-control"
                          v-model="rule.price"
                        />
                      </div>
                    </div>

                    <div
                      class="col-lg-2 mt-2"
                      v-if="
                        branch.paymentLiqPayEnabled ||
                        branch.paymentMonobankEnabled
                      "
                    >
                      <label class="text-md">Передплата</label>
                      <SelectApp
                        v-model="additionalRulesOptions[i].prepaymentPercentage"
                        :settings="{
                          options: prepaymentOptions,
                          trackBy: 'value',
                          label: 'label',
                          placeholder: '% від ціни',
                          closeOnSelect: true,
                          taggable: true,
                          multiple: false,
                        }"
                        @select="selectAdditionalRulePrepayment(i)"
                      />
                    </div>

                    <div class="col-lg-4 mt-2" v-if="optionsSelect?.length">
                      <label class="text-md" v-if="company.crmMode !== 'hotel'"
                        >Оберіть майстрів</label
                      >
                      <label class="text-md" v-if="company.crmMode === 'hotel'"
                        >Оберіть номери</label
                      >
                      <br />
                      <SelectApp
                        v-model="additionalRulesOptions[i].selectedStaffs"
                        :settings="{
                          options: optionsSelect,
                          trackBy: 'code',
                          label: 'name',
                          placeholder: 'Оберіть номери',
                          closeOnSelect: false,
                          taggable: true,
                          multiple: true,
                        }"
                        @select="onAdditionalRuleStaffSelect(i)"
                        @remove="onAdditionalRuleStaffSelect(i)"
                      />
                    </div>

                    <div class="row mt-2">
                      <div class="col-lg-4">
                        <label class="text-md">Опис послуги</label>
                        <div class="input-group input-group-outline">
                          <textarea
                            class="form-control"
                            style="height: 75px"
                            v-model="rule.description"
                            ref="description"
                            rows="2"
                            @input="autoResizeTextarea"
                          ></textarea>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <label class="text-md">Додаткові правила</label>
                      <div class="form-check customCheckbox">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="rule.allowForOnlineForm"
                          id="fcustomCheck1"
                        />
                        <label
                          class="custom-control-label customLabelCheckbox"
                          for="customCheck1"
                          >{{ getLabelColumnAllowedForOnline() }}
                        </label>
                      </div>
                      <div
                        class="form-check customCheckbox"
                        v-for="(v, k, i) in additionalRulesOptions[i]
                          .customRules"
                        v-bind:key="i"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="rule[k]"
                          id="fcustomCheck1"
                        />
                        <label
                          class="custom-control-label customLabelCheckbox"
                          for="customCheck1"
                          >{{ v.description }}
                        </label>
                        <a
                          v-if="v.tooltip && v.tooltip.length"
                          href="javascript:"
                          class="mx-1"
                          data-bs-toggle="tooltip"
                          :data-bs-original-title="v.tooltip"
                        >
                          <i
                            class="material-icons text-secondary position-relative text-lg"
                            >info</i
                          >
                        </a>
                      </div>
                    </div>

                    <div class="row mt-2" v-if="rule.isPricePerGuest">
                      <div
                        class="col-lg-2"
                        v-for="item in additionalRulesOptions[i].pricesPerGuest"
                        v-bind:key="item"
                      >
                        <label class="text-md"
                          >Ціна за {{ item.guests }} гостя</label
                        >
                        <div class="input-group input-group-outline">
                          <input
                            type="tel"
                            placeholder="0 грн"
                            class="form-control"
                            v-model="item.price"
                          />
                        </div>
                      </div>
                    </div>

                    <div class="row mt-2" v-if="company.crmMode === 'hotel'">
                      <div class="col">
                        <button
                          type="button"
                          class="btn btn-outline-danger"
                          @click="removeAdditionalRule(i)"
                        >
                          Видалити
                        </button>
                      </div>
                    </div>

                    <hr />
                  </div>
                </div>
              </template>
            </div>
            <!-- Додаткове правило кінець -->

            <div
              class="row mt-2"
              v-if="company.crmMode === 'hotel' && service.isSystem"
            >
              <div class="col">
                <button
                  type="button"
                  class="btn btn-outline-primary"
                  @click="addRuleTemplate"
                >
                  Додати правило
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- Видалення -->
        <div class="card mt-4" id="delete" v-if="showDeleteBlock()">
          <div class="badge bg-gradient-danger">DANGER ZONE</div>
          <div class="card-body">
            <div class="d-flex align-items-center mb-sm-0 mb-4">
              <div class="w-50">
                <h6>Видалити додаткову послугу</h6>
              </div>
              <div class="w-50 text-end">
                <button
                  class="btn bg-gradient-danger mb-0 ms-2"
                  type="button"
                  name="button"
                  data-bs-toggle="modal"
                  :data-bs-target="`#${deleteServiceModalParameters.modalId}`"
                  :disabled="
                    service.name === 'Ранній заїзд' ||
                    service.name === 'Пізній виїзд'
                  "
                >
                  Видалити
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Видалення finish -->

        <!--  Зберегти -->
        <div class="fixed-bottom">
          <div class="row">
            <div class="col-10"></div>
            <div class="col-lg-2">
              <div class="mx-4">
                <button
                  type="button"
                  class="block btn bg-gradient-primary"
                  @click="save"
                  :disabled="!preSaveValidation()"
                >
                  Зберегти
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--  Зберегти finish-->
      </div>
    </div>
  </div>

  <BmnCustomPopup :params="deleteServiceModalParameters"></BmnCustomPopup>
</template>
<style scoped>
.customLabelCheckbox {
  padding-left: 5px;
}
.customCheckbox {
  padding-left: 0px;
}
.block {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}
.available {
  display: flex;
  align-items: center; /* Выравниваем элементы по центру по вертикали */
  justify-content: flex-end;
}
.element {
  margin-right: 10px;
}
.container-fluid {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}
.card {
  border-radius: 0px;
}
</style>
<script>
import setTooltip from "@/assets/js/tooltip.js";
import api from "@/services/api";
import BmnCustomPopup from "@/components/BmnCustomPopup.vue";

import { useFetch } from "@/composables/common/api/useFetch";
import Spinner from "@/views/shared/Spinner.vue";
import SelectApp from "@/components/common/SelectApp.vue";
import { autoResizeTextarea } from "@/components/common/functions/autoResizeTextarea";
import allowedShowDevFeatures from "@/services/allowedShowDevFeatures";

const { data: dataResources, fetchData: getResourcesApi } = useFetch(
  api.staff.list,
);

async function getResources() {
  const params = {
    isActive: "1",
  };
  await getResourcesApi([params]);
  dataResources.value = dataResources.value.map((item) => {
    return {
      id: item._id,
      title: item.name,
      rank: item.rank,
      maxGuests: item.checkinRules?.guestsMax,
    };
  });

  return dataResources.value;
}

export default {
  async beforeMount() {
    this.showDevFeatures = allowedShowDevFeatures();
    this.company = await api.company.show();
    this.branch = (await api.branches.list())[0];

    this.staffs = await getResources();
    this.optionsSelect = this.staffs.map((item) => ({
      name: item.title,
      code: item.id,
      maxGuests: item.maxGuests,
    }));

    const optionSelectAll = {
      name: "Всі",
      code: 0,
      $isDisabled: true,
    };

    this.optionsSelect.unshift(optionSelectAll);

    this.prepaymentOptions = [
      {
        value: 0,
        label: "Без передплати",
      },
    ];
    for (const percent of [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]) {
      this.prepaymentOptions.push({
        value: percent,
        label: `${percent}%`,
      });
    }
    this.prepaymentPercentage = this.prepaymentOptions[0];

    if (this.$route.params.id) {
      this.mode = "edit";
      this.service = await api.additionalServices.show(this.$route.params.id);

      if (this.service.name === "Ранній заїзд") {
        this.customRules = {
          isAvailableNoPreviousDayBookings: {
            description: "Доступна в разі вільної попередньої доби",
            tooltip:
              "Бронюючи онлайн, ваш Гість буде бачити цю додаткову послугу лише якщо попередня до його заїзду доба - вільна. Наприклад, це зручно для раннього заїзду.",
          },
          prohibitPreviousDayBooking: {
            description: "Закрити продаж попереднього дня",
            tooltip:
              "Якщо ваш Гість обере цю додаткову послугу, то доба перед заїздом буде автоматично закрита для бронювання інших гостів.",
          },
          isPricePerGuest: {
            description: "Ціна залежить від кількості гостів",
            tooltip:
              "Можливість встановити різну ціну послуги для різної кількості гостів",
          },
        };
      }

      if (this.service.name === "Пізній виїзд") {
        this.customRules = {
          isPricePerGuest: {
            description: "Ціна залежить від кількості гостів",
            tooltip:
              "Можливість встановити різну ціну послуги для різної кількості гостів",
          },
          isAvailableNoNextDayBookings: {
            description: "Доступна в разі вільної наступної доби",
            tooltip:
              "Бронюючи онлайн, ваш Гість буде бачити цю додаткову послугу лише якщо наступна після виселення доба - вільна. Наприклад, це зручно для пізнього виїзду.",
          },
          prohibitNextDayBooking: {
            description: "Закрити продаж наступного дня",
            tooltip:
              "Якщо ваш Гість обере цю додаткову послугу, то доба після дати виїзду Гостя буде автоматично закрита від бронювання іншими гостями.",
          },
        };
      }

      if (this.service.isSystem) {
        if (!this.service.additionalRules) {
          this.service.additionalRules = [];
        }

        for (const rule of this.service.additionalRules) {
          const prepaymentOption = this.prepaymentOptions.find((option) => {
            return option.value === rule.prepaymentPercentage;
          });

          let maxGuests = 0;
          let selectedStaffs;

          if (rule.staffIds.length === 0) {
            selectedStaffs = [this.optionsSelect[0]];
            for (const staff of this.staffs) {
              if (staff.maxGuests > maxGuests) {
                maxGuests = staff.maxGuests;
              }
            }
          } else {
            selectedStaffs = this.staffs
              .filter((s) => {
                for (const staff of rule.staffIds) {
                  if (staff === s.id) {
                    return true;
                  }
                }

                return false;
              })
              .map((s) => {
                return {
                  name: s.title,
                  code: s.id,
                  maxGuests: s.maxGuests,
                };
              });

            for (const staff of selectedStaffs) {
              if (staff.maxGuests > maxGuests) {
                maxGuests = staff.maxGuests;
              }
            }
          }

          let pricesPerGuest = [];
          if (rule.pricesPerGuest && rule.pricesPerGuest?.length) {
            pricesPerGuest = rule.pricesPerGuest;
          } else {
            for (let i = 1; i <= maxGuests; i++) {
              pricesPerGuest.push({
                guests: i,
                price: undefined,
              });
            }
          }

          this.additionalRulesOptions.push({
            selectedStaffs,
            prepaymentPercentage: prepaymentOption,
            maxGuests,
            pricesPerGuest,
            customRules: this.customRules,
          });
        }
      }

      if (this.service.prepaymentPercentage) {
        this.prepaymentPercentage = this.prepaymentOptions.find(
          (option) => option.value === this.service.prepaymentPercentage,
        );
      }

      this.selectedStaffs = this.staffs
        .filter((s) => {
          for (const staff of this.service.staffIds) {
            if (staff._id === s.id) {
              return true;
            }
          }

          return false;
        })
        .map((s) => {
          return {
            name: s.title,
            code: s.id,
            maxGuests: s.maxGuests,
          };
        });

      for (const staff of this.selectedStaffs) {
        if (staff.maxGuests > this.maxGuests) {
          this.maxGuests = staff.maxGuests;
        }
      }

      if (this.selectedStaffs.length === 0) {
        this.selectedStaffs.push(optionSelectAll);

        for (const staff of this.staffs) {
          if (staff.maxGuests > this.maxGuests) {
            this.maxGuests = staff.maxGuests;
          }
        }
      }

      if (this.service.isPricePerGuest) {
        this.pricesPerGuest = this.service.pricesPerGuest;
      }
    } else {
      this.selectedStaffs.push(optionSelectAll);
      for (const staff of this.staffs) {
        if (staff.maxGuests > this.maxGuests) {
          this.maxGuests = staff.maxGuests;
        }
      }
    }

    this.dataLoaded = true;
  },

  components: {
    SelectApp,
    BmnCustomPopup,
    Spinner,
  },

  async mounted() {
    setTimeout(autoResizeTextarea, 1000, {
      target: this.$refs.description,
    });
    setTimeout(setTooltip, 200);
  },

  data() {
    return {
      showDevFeatures: false,
      dataLoaded: false,
      mode: "create", // create | edit

      company: {
        crmMode: "",
      },
      branch: {
        paymentMonobankEnabled: false,
        paymentLiqPayEnabled: false,
      },

      customRules: {
        isPricePerDay: {
          description: "Ціна подобова",
        },
        isPricePerGuest: {
          description: "Ціна за кожного гостя",
        },
        isPreselected: {
          description: "Обрана за замовчуванням",
        },
      },

      positions: [],
      staffs: [],
      optionsSelect: [],
      selectedStaffs: [],
      maxGuests: 0,
      prepaymentPercentage: 0,
      prepaymentOptions: [],
      pricesPerGuest: [],
      additionalRulesOptions: [],
      service: {
        name: "",
        description: "",
        price: 0,
        pricesPerGuest: [],
        prepaymentAmount: 0,
        prepaymentPercentage: 0,
        isPricePerDay: false,
        isPricePerGuest: false,
        isPreselected: false,
        isActive: true,
        allowForOnlineForm: true,
        isSystem: false,
        staffIds: [],
        additionalRules: [],
      },
      deleteServiceModalParameters: {
        modalId: "modal-delete-1",
        modalTitle: "Видалення додаткової послуги",
        modalDescription: "Ви впевнені, що хочете видалити додаткову послугу?",
        modalButtonConfirmText: "Видалити",
        modalButtonCancelText: "Відмінити",
        confirmHandler: async () => {
          await api.additionalServices.delete(this.service._id);
          this.$store.commit("addToast", {
            title: "Видалено",
          });
          await this.$router.push("/settings/additional-services");
        },
      },
    };
  },

  methods: {
    autoResizeTextarea,

    getLabelColumnAllowedForOnline() {
      if (this.company.crmMode === "hotel") {
        return "Доступна для онлайн бронювання";
      }

      return "Доступна для онлайн запису";
    },

    async save() {
      try {
        this.service.staffIds = this.selectedStaffs
          .map((s) => {
            return s.code;
          })
          .filter((code) => {
            return code !== 0;
          });

        if (this.service.isPricePerGuest) {
          for (const item of this.pricesPerGuest) {
            if (!item.price) {
              this.$store.commit("addToast", {
                title: `Ціна за ${item.guests} гостя не встановлена`,
                type: "error",
              });
              return;
            }
          }
          this.service.pricesPerGuest = this.pricesPerGuest;
        }

        // TODO: Perhaps, not needed
        if (this.service.isSystem && this.service.additionalRules.length) {
          // TODO: Simplify!
          for (let i = 0; i < this.service.additionalRules.length; i++) {
            this.service.additionalRules[i].staffIds =
              this.additionalRulesOptions[i].selectedStaffs
                .map((s) => {
                  return s.code;
                })
                .filter((code) => {
                  return code !== 0;
                });

            if (!this.service.additionalRules[i].isPricePerGuest) {
              continue;
            }

            const unsetPrice = this.additionalRulesOptions[
              i
            ].pricesPerGuest.find((item) => {
              return !item.price;
            });

            if (unsetPrice) {
              this.$store.commit("addToast", {
                title: `Ціна в додатковому правилі ${i + 1} за ${
                  unsetPrice.guests
                } гостя не встановлена`,
                type: "error",
              });

              return;
            }

            this.service.additionalRules[i].pricesPerGuest =
              this.additionalRulesOptions[i].pricesPerGuest;
          }
        }

        if (this.mode === "create") {
          await api.additionalServices.create(this.service);
        } else if (this.mode === "edit") {
          await api.additionalServices.update(
            this.$route.params.id,
            this.service,
          );
        }

        this.$store.commit("addToast", {
          title: "Збережено",
        });

        await this.$router.push("/settings/additional-services");
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },

    prohibitChangingServiceName() {
      return this.mode === "edit" && this.service.isSystem;
    },

    preSaveValidation() {
      return this.service.name;
    },

    onMultiselectSelect(selectedOrRemovedOption, id) {
      this.maxGuests = 0;
      if (this.selectedStaffs.length === 1) {
        this.maxGuests = this.selectedStaffs[0].maxGuests;
        return;
      }

      if (this.selectedStaffs.length === 0) {
        this.selectedStaffs.push(this.optionsSelect[0]);
        for (const staff of this.staffs) {
          if (staff.maxGuests > this.maxGuests) {
            this.maxGuests = staff.maxGuests;
          }
        }
        return;
      }

      const optionAll = this.selectedStaffs.find((option) => {
        return option.code === 0;
      });

      if (optionAll) {
        this.selectedStaffs = this.selectedStaffs.filter((option) => {
          return option.code !== 0;
        });
      }

      this.maxGuests = 0;
      for (const staff of this.selectedStaffs) {
        if (staff.maxGuests > this.maxGuests) {
          this.maxGuests = staff.maxGuests;
        }
      }
    },

    resizePricesPerGuest(index, oldSize, newSize) {
      if (oldSize == 0) {
        if (this.additionalRulesOptions[index].pricesPerGuest?.length === 0) {
          for (let i = 1; i <= newSize; i++) {
            this.additionalRulesOptions[index].pricesPerGuest.push({
              guests: i,
              price: undefined,
            });
          }
        }

        return;
      }

      if (oldSize < newSize) {
        for (let i = oldSize + 1; i <= newSize; i++) {
          this.additionalRulesOptions[index].pricesPerGuest.push({
            guests: i,
            price: undefined,
          });
        }

        return;
      }

      for (let i = oldSize; i > newSize; i--) {
        this.additionalRulesOptions[index].pricesPerGuest.pop();
      }
    },

    onAdditionalRuleStaffSelect(index, selectedOrRemovedOption, id) {
      const selectedStaffs = this.additionalRulesOptions[index].selectedStaffs;

      const oldMaxGuests = this.additionalRulesOptions[index].maxGuests;

      this.additionalRulesOptions[index].maxGuests = 0;
      if (selectedStaffs.length === 1) {
        this.additionalRulesOptions[index].maxGuests =
          selectedStaffs[0].maxGuests;
        this.resizePricesPerGuest(
          index,
          oldMaxGuests,
          selectedStaffs[0].maxGuests,
        );
        return;
      }

      if (selectedStaffs.length === 0) {
        this.additionalRulesOptions[index].selectedStaffs.push(
          this.optionsSelect[0],
        );
        for (const staff of this.staffs) {
          if (staff.maxGuests > this.additionalRulesOptions[index].maxGuests) {
            this.additionalRulesOptions[index].maxGuests = staff.maxGuests;
          }
        }

        this.resizePricesPerGuest(
          index,
          oldMaxGuests,
          this.additionalRulesOptions[index].maxGuests,
        );
        return;
      }

      const optionAll = selectedStaffs.find((option) => {
        return option.code === 0;
      });

      if (optionAll) {
        this.additionalRulesOptions[index].selectedStaffs =
          selectedStaffs.filter((option) => {
            return option.code !== 0;
          });
      }

      for (const staff of this.additionalRulesOptions[index].selectedStaffs) {
        if (staff.maxGuests > this.additionalRulesOptions[index].maxGuests) {
          this.additionalRulesOptions[index].maxGuests = staff.maxGuests;
        }
      }
      this.resizePricesPerGuest(
        index,
        oldMaxGuests,
        this.additionalRulesOptions[index].maxGuests,
      );
    },

    selectPrepaymentPercentage(option) {
      this.service.prepaymentPercentage = option.value;
    },
    showDeleteBlock() {
      return this.mode === "edit" && !this.service.isSystem;
    },
    addRuleTemplate() {
      this.service.additionalRules.push({
        description: "",
        staffIds: [],
        price: 0,
        pricesPerGuest: [],
        prepaymentPercentage: 0,
        isPricePerGuest: false,
        allowForOnlineForm: false,
        isAvailableNoPreviousDayBookings: false,
        isAvailableNoNextDayBookings: false,
        prohibitPreviousDayBooking: false,
        prohibitNextDayBooking: false,
      });

      let maxGuests = 0;
      for (const staff of this.staffs) {
        if (staff.maxGuests > maxGuests) {
          maxGuests = staff.maxGuests;
        }
      }

      const pricesPerGuest = [];
      for (let i = 1; i <= maxGuests; i++) {
        pricesPerGuest.push({
          guests: i,
          price: undefined,
        });
      }

      this.additionalRulesOptions.push({
        selectedStaffs: this.optionsSelect[0],
        prepaymentPercentage: this.prepaymentOptions[0],
        maxGuests,
        pricesPerGuest,
        customRules: this.customRules,
      });
    },
    removeAdditionalRule(index) {
      this.service.additionalRules.splice(index, 1);
      this.additionalRulesOptions.splice(index, 1);
    },
    selectAdditionalRulePrepayment(index) {
      this.service.additionalRules[index].prepaymentPercentage =
        this.additionalRulesOptions[index].prepaymentPercentage.value;
    },
  },
  watch: {
    maxGuests: function (val, oldVal) {
      if (!this.service.isPricePerGuest) {
        this.pricesPerGuest = [];
        return;
      }

      if (oldVal === 0) {
        if (!this.pricesPerGuest) {
          this.pricesPerGuest = [];
        }

        if (this.pricesPerGuest?.length === 0) {
          for (let i = oldVal + 1; i <= val; i++) {
            this.pricesPerGuest.push({
              guests: i,
              price: undefined,
            });
          }
        }

        return;
      }

      if (oldVal < val) {
        for (let i = oldVal + 1; i <= val; i++) {
          this.pricesPerGuest.push({
            guests: i,
            price: undefined,
          });
        }

        return;
      }

      for (let i = oldVal; i > val; i--) {
        this.pricesPerGuest.pop();
      }
    },
    "service.isPricePerGuest": function (val) {
      if (!val) {
        return;
      }

      if (this.pricesPerGuest?.length) {
        return;
      }

      this.pricesPerGuest = [];
      for (let i = 1; i <= this.maxGuests; i++) {
        this.pricesPerGuest.push({
          guests: i,
          price: undefined,
        });
      }
    },
  },
};
</script>
