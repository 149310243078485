<script setup>
import { onBeforeMount, ref } from "vue";
import { RouterLink } from "vue-router";
import Spinner from "@/views/shared/Spinner.vue";
import api from "@/services/api";

const dataLoaded = ref(false);

const company = ref({
  smsBalance: 0,
  smsSenderName: "",
});

onBeforeMount(async () => {
  company.value = await api.company.show();
  dataLoaded.value = true;
});

const links = ref([
  {
    icon: "article",
    title: "Шаблони СМС",
    description: "Налаштування текстів для автоматичних відправлень",
    url: "/integrations/sms-templates",
  },
  {
    icon: "send",
    title: "СМС Розсилки",
    description: "Відправлення СМС розсилок по клієнтській базі",
    url: "/integrations/sms-manual-sends",
  },
  {
    icon: "schedule",
    title: "Історія відправлень",
    description: "Вся історія відправлень автоматичних СМС",
    url: "/integrations/sms-history",
  },
  {
    icon: "payments",
    title: "Поповнення балансу",
    description: "Поповнити баланс для використання сервісу",
    url: "/integrations/sms-payment",
  },
  {
    icon: "settings",
    title: "Налаштування",
    description: "Налаштування логіки відправлень автоматичних СМС",
    url: "/integrations/sms-settings",
  },
]);
</script>

<template>
  <div v-show="!dataLoaded" class="container-fluid">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div v-show="dataLoaded" class="container-fluid">
    <div class="row mt-3">
      <div class="col-8"></div>
      <div class="col-lg-4 text-end">
        <span class="text-bold"> Баланс: </span
        ><span class=""> {{ company.smsBalance }} СМС </span>
      </div>
    </div>
    <div class="row mt-2">
      <ul class="list-group list">
        <li v-for="(link, index) of links" :key="index" class="mb-2 li-class">
          <RouterLink
            :to="{ path: link.url }"
            class="d-flex align-items-center bg-white gap-1 sms-btn px-3 py-2 rounded-16"
          >
            <div class="avatar me-3">
              <span class="material-symbols-outlined text-dark">
                {{ link.icon }}
              </span>
            </div>
            <div
              class="d-flex align-items-start flex-column justify-content-center"
            >
              <h6 class="mb-0 text-sm">{{ link.title }}</h6>
              <p class="mb-0 text-xs">
                {{ link.description }}
              </p>
            </div>
            <span class="btn btn-link pe-2 ps-0 mb-0 ms-auto flex-shrink-0"
              >Перейти</span
            >
          </RouterLink>
        </li>
      </ul>
    </div>
    <div id="senderName" class="card mt-3">
      <div class="card-body mt-2">
        <h3>Ваше імʼя відправника</h3>
        <p class="text-sm mb-0"></p>
        <div class="row">
          <div class="col-lg-2 mt-2">
            <div class="input-group input-group-outline">
              <input
                v-model="company.smsSenderName"
                class="form-control"
                disabled="disabled"
                type="text"
              />
            </div>
          </div>
          <div class="col-lg-10 mt-2">
            <div class="row infoBlock">
              <div class="d-flex align-items-center gap-2">
                <span class="material-symbols-outlined"> info </span>
                <label class="text-xs" style="color: #458466">
                  bookmenow - імʼя відправника за замовчуванням. Якщо бажаєте
                  змінити імʼя відправника, напишіть, будь ласка, нам в технічну
                  підтримку і ми за вашим запитом зареєструємо для вас унікальне
                  Імʼя.<br />
                  Процес реєстрації займає ± 14 днів з момента відправки заявки.
                  <br />
                  Імʼя відправника має відповідати довжині 3-11 латинських
                  символів.
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.list {
  list-style-type: none;
}
.infoBlock {
  color: #458466;
  background-color: #deede3;
  border-radius: 8px;
  margin-bottom: 16px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 12px 12px 12px 12px;
}
.sms-btn {
  /* transition: all 0.3s ease; */
  /* cursor: pointer; */

  &:active {
    background: #deede3 !important;
    border: 1.5px solid #070707;
  }
}
/* .li-class {
  border: 0.5px solid #DDDDDD;
  border-radius: 16px;
} */
.rounded-16 {
  border-radius: 16px !important;
}
.sms-btn {
  border: 0.5px solid black;
}

.sms-btn:hover {
  background-color: #f1f8f4 !important;
  border: 0.5px solid #dddddd;
}
.container-fluid {
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
</style>
