<template>
  <div class="container-fluid my-3 py-3" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="container-fluid" v-show="dataLoaded">
    <div class="row">
      <div class="row">
        <div class="col-lg-12">
          <!-- monobank acquiring -->
          <div class="card" id="additional-settings">
            <div class="card-body">
              <div class="col-lg-6 confirmed">
                <div class="element mt-2">
                  <h3>Оплата онлайн</h3>
                </div>
                <div class="form-check mt-2 form-switch text-center">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="onlinePaymentEnabled"
                    @change="save"
                    :disabled="
                      !showDevFeatures || branchTemplate.paymentLiqPayEnabled
                    "
                  />
                </div>
              </div>
              <div class="center-container">
                <img
                  src="@/assets/img/illustrations/online-payment.jpeg"
                  alt="Online Payment"
                  class="responsive-img"
                />
              </div>
              <div class="row">
                <span class="text-sm font-weight-normal">
                  Ваші гості отримають можливість сплатити одразу під час
                  бронювання. Вартість передплати визначаєте ви. Для опрацювання
                  платежів ми використовуємо еквайрінг нашого стратегічного
                  партнера - <b>plata by mono</b>.<br />
                  Комісія за еквайрінг складає 2.3%. <br />
                  Для підключення онлайн оплати у вас має бути відкритим
                  розрахунковий рахунок ФОП в monobank.
                </span>
              </div>
              <label
                ><a
                  href="https://bookmenow.pro/kb/how-ecquiring-comission-works"
                  style="text-decoration: underline"
                  >Детальніше про комісію</a
                ></label
              >
              <div
                class="row"
                v-if="
                  branchTemplate.paymentMonobankEnabled ||
                  branchTemplate.paymentLiqPayEnabled
                "
              >
                <div class="row mt-3">
                  <div class="col-lg-3">
                    <label>Сума передплати</label>
                    <SelectApp
                      v-model="selectedPrepaymentOption"
                      :settings="{
                        taggable: true,
                        multiple: false,
                        closeOnSelect: true,
                        options: prepaymentOptions,
                        trackBy: 'value',
                        label: 'label',
                        placeholder: 'Оберіть передплату',
                        groupSelect: true,
                      }"
                      @select="savePrepaymentOption"
                    />
                  </div>
                </div>
              </div>
              <div
                class="row mt-3"
                v-if="branchTemplate.paymentMonobankEnabled"
              >
                <div
                  class="col-lg-12"
                  v-if="branchTemplate.providerPaymentMonobank.submerchant.iban"
                >
                  <span class="text-sm font-weight-normal">
                    IBAN для виплат:<br />
                    {{
                      branchTemplate.providerPaymentMonobank.submerchant.iban
                    }}
                  </span>
                </div>
              </div>
              <div
                class="row"
                v-if="
                  !branchTemplate.paymentMonobankEnabled &&
                  !branchTemplate.paymentLiqPayEnabled
                "
              >
                <div class="col-lg-12 my-auto mt-3">
                  <h6>Підключити онлайн оплату</h6>
                  <p class="text-muted text-sm mt-3">
                    Для підключення можливості приймати оплати онлайн -
                    заповніть, будь ласка, форму:
                  </p>
                  <div class="">
                    <strong>
                      <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSdp3biJ5lHL3RhaakgVyLXhYv52-rSNQXTlaHdOl3MNjevo2Q/viewform"
                        class="btn btn-md bg-gradient-primary mb-0"
                      >
                        Заповнити форму
                      </a>
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- monobank acquiring finish -->
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.responsive-img {
  width: 100%; /* По умолчанию занимает 100% ширины родительского элемента */
  height: auto; /* Сохраняет пропорции */
  max-width: 100%; /* Предотвращает выход за пределы родительского контейнера */
}

/* Ограничение ширины до 350px для десктопов */
@media (min-width: 768px) {
  /* Применяется для экранов шире 768px */
  .responsive-img {
    max-width: 350px;
  }
}
.confirmed {
  display: flex;
  align-items: center; /* Выравниваем элементы по центру по вертикали */
}
.element {
  margin-right: 10px;
}
.container-fluid {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}
.card {
  border-radius: 0px;
}
</style>

<script>
import api from "@/services/api";
import Spinner from "@/views/shared/Spinner.vue";
import SelectApp from "@/components/common/SelectApp.vue";
import allowedShowDevFeatures from "@/services/allowedShowDevFeatures";

export default {
  name: "integrations-monobank",

  created() {
    this.showDevFeatures = allowedShowDevFeatures();
  },

  async mounted() {
    this.company = await api.company.show();
    this.branchTemplate = (await api.branches.list())[0];
    this.onlinePaymentEnabled =
      this.branchTemplate.paymentMonobankEnabled ||
      this.branchTemplate.paymentLiqPayEnabled;

    this.prepaymentOptions = [
      {
        value: 0,
        label: "Без передплати",
      },
    ];

    if (this.company.crmMode === "hotel") {
      this.prepaymentOptions.push({
        value: "prepayment-first-day",
        label: "Вартість першої доби",
      });
    }

    for (const percent of [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]) {
      this.prepaymentOptions.push({
        value: percent,
        label: `${percent}%`,
      });
    }

    if (this.branchTemplate.prepaymentAmountFirstDay) {
      this.selectedPrepaymentOption = this.prepaymentOptions[1];
    } else {
      this.selectedPrepaymentOption =
        this.prepaymentOptions.find(
          (option) =>
            option.value === this.branchTemplate.prepaymentAmountPercentage,
        ) || this.prepaymentOptions[0];
    }

    this.dataLoaded = true;
  },

  data() {
    return {
      showDevFeatures: false,
      dataLoaded: false,

      company: {
        crmMode: "",
      },
      prepaymentOptions: [],
      selectedPrepaymentOption: {},

      onlinePaymentEnabled: false,

      branchTemplate: {
        paymentLiqPayEnabled: false,
        paymentMonobankEnabled: false,
        paymentMonobankToken: null, // deprecated
        providerPaymentMonobank: {
          submerchant: {
            isActive: false,
            iban: "",
          },
        },
        prepaymentAmountPercentage: 0,
        prepaymentAmountFirstDay: false,
      },
    };
  },

  components: {
    Spinner,
    SelectApp,
  },
  methods: {
    async save() {
      if (!this.dataLoaded) {
        return null;
      }

      if (this.branchTemplate.paymentLiqPayEnabled) {
        return null;
      }

      try {
        await api.branches.updateOne(this.branchTemplate._id, {
          paymentMonobankEnabled: this.onlinePaymentEnabled,
        });

        this.branchTemplate.paymentMonobankEnabled = this.onlinePaymentEnabled;

        this.$store.commit("addToast", {
          title: "Збережено",
        });
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },

    async savePrepaymentOption(input) {
      if (!this.dataLoaded) {
        return null;
      }

      if (input.value === "prepayment-first-day") {
        this.branchTemplate.prepaymentAmountFirstDay = true;
        this.branchTemplate.prepaymentAmountPercentage = 0;
      } else {
        this.branchTemplate.prepaymentAmountFirstDay = false;
        this.branchTemplate.prepaymentAmountPercentage = input.value;
      }

      try {
        await api.branches.updateOne(this.branchTemplate._id, {
          prepaymentAmountPercentage:
            this.branchTemplate.prepaymentAmountPercentage,
          prepaymentAmountFirstDay:
            this.branchTemplate.prepaymentAmountFirstDay,
        });

        this.$store.commit("addToast", {
          title: "Збережено",
        });
      } catch {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },
  },
};
</script>
