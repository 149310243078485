<script>
import * as _ from "lodash";
import Dropzone from "dropzone";
import setTooltip from "@/assets/js/tooltip.js";
import Spinner from "@/views/shared/Spinner.vue";
import api from "@/services/api";
import * as timeSlotConverter from "@/utils/timeSlotConverter";
import BmnCustomPopup from "@/components/BmnCustomPopup.vue";
import { autoResizeTextarea } from "@/components/common/functions/autoResizeTextarea";
import delay from "@/utils/delay";
import allowedShowDevFeatures from "@/services/allowedShowDevFeatures";
import config from "@/config";
import { useFile } from "@/composables/useFile";

const API_URL_BASE = config.API_URL_BASE;

export default {
  name: "settings-service",

  components: {
    BmnCustomPopup,
    Spinner,
  },

  async mounted() {
    this.showDevFeatures = allowedShowDevFeatures();

    this.company = await api.company.show();
    this.branch = (await api.branches.list())[0];

    this.positions = await api.positions.list();

    if (this.$route.params.id) {
      this.mode = "edit";
      this.serviceTemplate = await api.services.findOne(this.$route.params.id);
      this.serviceTemplate.prices = this.serviceTemplate.prices || [];
      if (!this.serviceTemplate.photo) {
        this.serviceTemplate.photo = {
          key: null,
          url: null,
        };
      }

      if (this.serviceTemplate.duration) {
        const parsed = timeSlotConverter
          .toString(this.serviceTemplate.duration, false)
          .split(":");
        this.duration.hours = parsed[0];
        this.duration.minutes = parsed[1];
      }
    } else {
      this.mode = "create";
    }

    const mapPricesByName = _.keyBy(
      this.serviceTemplate.prices,
      "positionId.name",
    );
    const mapPositionsByName = _.keyBy(this.positions, "name");

    const tmpPrices = [];
    _.forEach(mapPositionsByName, (position) => {
      const singlePrice = {
        positionId: position._id,
        positionName: position.name,
        price: _.get(mapPricesByName[position.name], "price", null),
        prepaymentAmount: _.get(
          mapPricesByName[position.name],
          "prepaymentAmount",
          0,
        ),
      };
      tmpPrices.push(singlePrice);
    });

    this.serviceTemplate.prices = tmpPrices;

    this.dataLoaded = true;

    setTimeout(autoResizeTextarea, 1000, {
      target: this.$refs.description,
    });

    await delay(200);

    const { rotateImage } = useFile();

    const dropzoneOptions = {
      url: `${API_URL_BASE}/settings/services/uploadPhoto`,
      method: "POST",
      headers: {
        "x-access-token": localStorage.getItem("accessToken"),
      },
      paramName: "file",
      maxFiles: 1,
      maxFilesize: 2 * 1024 * 1024, // 2 MB
      createImageThumbnails: true,
      addRemoveLinks: false,
      transformFile(file, done) {
        rotateImage(file, done);
      },
    };

    const dropzone = new Dropzone("#servicePhoto", dropzoneOptions);

    dropzone.on("success", (file, result) => {
      this.serviceTemplate.photo.key = result.photoKey;
      this.serviceTemplate.photo.url = result.photoUrl;

      dropzone.removeFile(file);
    });

    dropzone.on("error", (file, message) => {
      console.warn(message);
    });

    setTooltip();
  },

  data() {
    return {
      showDevFeatures: false,
      dataLoaded: false,
      mode: "", // create | edit

      company: {
        crmMode: null,
        localizationMode: null,
      },
      branch: {
        paymentMonobankEnabled: false,
      },

      positions: [],
      serviceTemplate: {
        name: "",
        duration: null,
        prices: [],
        isActive: true,
        photo: {
          key: "",
          url: "",
        },
        onlineBooking: true,
        discountNewClient: null,
        groupEnabled: false,
        groupLimit: 10,
      },

      duration: {
        hours: null,
        minutes: null,
      },

      modalParamsRemoveService: {
        modalId: "modal-default-1",
        modalTitle: "Видалення",
        modalDescription:
          "Ви впевнені, що хочете видалити цю послугу? Після видалення послугу відновити буде неможливо.",
        modalButtonCancelText: "Відмінити",
        modalButtonConfirmText: "Видалити",
        data: {},
        confirmHandler: async () => {
          try {
            await api.services.removeOne(this.$route.params.id);

            this.$store.commit("addToast", {
              title: "Збережено",
            });

            await this.$router.push("/settings/services");
          } catch (error) {
            this.$store.commit("addToast", {
              title: "Виникла помилка. Спробуйте ще раз",
              type: "error",
            });
          }
        },
      },
    };
  },

  methods: {
    autoResizeTextarea,
    showDuration() {
      return this.company.crmMode !== "hotel";
    },

    onDurationChange() {
      const hours = _.toNumber(this.duration.hours);
      if (!hours || hours < 0 || hours > 23) {
        this.duration.hours = 0;
      }

      const minutes = _.toNumber(this.duration.minutes);
      if (!minutes || minutes < 0 || minutes > 59) {
        this.duration.minutes = 0;
      }
    },

    async save() {
      const _hours = _.toNumber(this.duration.hours) || 0;
      const _minutes = _.toNumber(this.duration.minutes) || 0;

      this.serviceTemplate.duration = _hours * 60 + _minutes;

      const payload = {
        name: this.serviceTemplate.name,
        duration: this.serviceTemplate.duration,
        prices: this.serviceTemplate.prices,
        multipleChoice: this.serviceTemplate.multipleChoice,
        onlinePaymentEnabled: this.serviceTemplate.onlinePaymentEnabled,
        onlineBooking: this.serviceTemplate.onlineBooking,
        description: this.serviceTemplate.description,
        photo: this.serviceTemplate.photo,
        discountNewClient: this.serviceTemplate.discountNewClient,
        groupEnabled: this.serviceTemplate.groupEnabled,
        groupLimit: this.serviceTemplate.groupLimit,
        isActive: this.serviceTemplate.isActive,
      };

      try {
        if (this.mode === "create") {
          await api.services.create(payload);
        } else if (this.mode === "edit") {
          await api.services.updateOne(this.$route.params.id, payload);
        }

        this.$store.commit("addToast", {
          title: "Збережено",
        });

        await this.$router.push("/settings/services");
      } catch (error) {
        this.$store.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      }
    },

    async removePhoto() {
      const payload = {
        key: this.serviceTemplate.photo.key,
        url: this.serviceTemplate.photo.url,
      };
      const API_URL = `${API_URL_BASE}/settings/services/removePhoto`;
      const options = {
        method: "DELETE",
        headers: {
          "content-type": "application/json",
          "x-access-token": localStorage.getItem("accessToken"),
        },
        body: JSON.stringify(payload),
      };

      const response = await fetch(API_URL, options);
      await response.json();

      this.serviceTemplate.photo.key = null;
      this.serviceTemplate.photo.url = null;
    },

    isValidDuration() {
      const hours = _.toNumber(this.duration.hours);
      const minutes = _.toNumber(this.duration.minutes);

      if (hours < 0) {
        return false;
      }
      if (minutes < 0 || minutes > 59) {
        return false;
      }

      if (!hours && !minutes) {
        return false;
      }

      return hours || minutes;
    },

    preSaveValidation() {
      const isValidPricing = true;

      if (this.serviceTemplate.groupEnabled) {
        if (!this.serviceTemplate.groupLimit) {
          return false;
        }
      }

      if (this.showDuration()) {
        return (
          isValidPricing && this.serviceTemplate.name && this.isValidDuration()
        );
      }

      return isValidPricing && this.serviceTemplate.name;
    },

    getLabelStaff() {
      const localizationMode = this.company.localizationMode;

      switch (true) {
        case localizationMode === "spa":
          return "ресурсу";
        case localizationMode === "hotel":
          return "номер";
        default:
          return "майстра";
      }
    },
  },
};
</script>

<template>
  <div class="container-fluid my-3 py-3" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="container-fluid" v-show="dataLoaded">
    <div class="row mb-2">
      <div class="available">
        <div class="element">Доступність</div>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="serviceTemplate.isActive"
          />
        </div>
      </div>
    </div>
    <div class="row mb-5">
      <div class="col-lg-12 mt-lg-0">
        <!-- Card Общая информация -->
        <div class="card" id="basic-info">
          <div class="card-header">
            <div class="row">
              <div class="col-lg-9">
                <h6>Загальна інформація</h6>
                <p class="text-sm mb-0">
                  Ціна послуги залежить від <strong>категорії</strong>
                  {{ getLabelStaff() }}, яку він займає. Таким чином, стрижка,
                  наприклад у "Головного барбера" может коштувати дорожче, ніж
                  та сама стрижка у "Стажера". Перед створенням послуги
                  переконайтесь, будь ласка, що ви створили хочаб одну
                  <a href="/settings/positions"><strong>категорію</strong></a>
                </p>
              </div>
            </div>
          </div>
          <div class="card-body pt-0">
            <div class="row">
              <div class="col-lg-3">
                <label class="form-label mb-0">Назва послуги</label>
                <div class="input-group input-group-outline">
                  <input
                    type="text"
                    class="form-control"
                    v-model="serviceTemplate.name"
                  />
                </div>
              </div>
              <div class="col-lg-3" v-if="showDuration()">
                <div class="row">
                  <label class="form-label mb-0">Тривалість</label>
                  <div class="w-50">
                    <div
                      class="input-group input-group-outline"
                      :class="{ 'is-filled': duration.hours }"
                    >
                      <label class="form-label">Год</label>
                      <input
                        type="tel"
                        class="form-control"
                        min="0"
                        max="23"
                        step="1"
                        @change="onDurationChange"
                        v-model="duration.hours"
                      />
                    </div>
                  </div>
                  <div class="w-50">
                    <div
                      class="input-group input-group-outline"
                      :class="{ 'is-filled': duration.minutes }"
                    >
                      <label class="form-label">Хв</label>
                      <input
                        type="tel"
                        class="form-control"
                        min="0"
                        max="59"
                        step="10"
                        @change="onDurationChange"
                        v-model="duration.minutes"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 mt-2">
                <label class="mb-0">Опис послуги</label>
                <div class="input-group input-group-outline">
                  <textarea
                    class="form-control"
                    v-model="serviceTemplate.description"
                    rows="2"
                    @input="autoResizeTextarea"
                    style="height: 75px"
                    ref="description"
                  ></textarea>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <label class="form-label mb-0">Знижка новому клієнту</label>
              <div class="col-lg-3">
                <div
                  class="input-group input-group-outline"
                  :class="{ 'is-filled': serviceTemplate.discountNewClient }"
                >
                  <label class="form-label">%</label>
                  <input
                    type="tel"
                    class="form-control"
                    min="0"
                    max="100"
                    step="5"
                    v-model="serviceTemplate.discountNewClient"
                  />
                </div>
              </div>
            </div>
            <hr class="horizontal dark mt-2 mb-1" />
            <div
              class="row mt-2"
              v-for="singlePrice in serviceTemplate.prices"
              :key="singlePrice.name"
            >
              <div class="col-lg-12">
                <label class="mb-0 text-md">Категорія: </label>
                <label>{{ singlePrice.positionName }}</label>
                <span class="d-block text-sm"> </span>
              </div>
              <div class="col-lg-4">
                <div class="row">
                  <div class="w-50">
                    <label class="mb-0 text-bold">Ціна (грн)</label>
                    <div class="input-group input-group-outline">
                      <input
                        class="multisteps-form__input form-control"
                        type="tel"
                        placeholder="100"
                        v-model="singlePrice.price"
                      />
                    </div>
                  </div>
                  <div
                    class="w-50"
                    v-if="
                      branch.paymentMonobankEnabled ||
                      branch.paymentLiqPayEnabled
                    "
                  >
                    <label class="mb-0 text-bold">Передоплата (грн)</label>
                    <div class="input-group input-group-outline">
                      <input
                        class="multisteps-form__input form-control"
                        type="tel"
                        placeholder="0"
                        v-model="singlePrice.prepaymentAmount"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr class="horizontal dark mt-2 mb-1" />
            </div>
            <div class="row"></div>
            <div class="row mt-3">
              <div class="col-lg-4">
                <div class="row">
                  <div class="col-10">
                    <label class="text-md ms-0">Онлайн запис</label>
                  </div>
                  <div class="col-2">
                    <div class="col-lg-3 col-sm-6">
                      <div class="form-check form-switch mt-2">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="serviceTemplate.onlineBooking"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-3">
              <div class="row">
                <div class="col-lg-4">
                  <div class="row">
                    <div class="col-10">
                      <label class="text-md ms-0">
                        Групова послуга
                        <a
                          href="javascript:;"
                          class="mx-3"
                          data-bs-toggle="tooltip"
                          data-bs-original-title="Максимальна кількість людей, що можна додати до запису на цю послугу."
                        >
                          <i
                            class="material-icons text-secondary position-relative text-lg"
                            >info</i
                          >
                        </a>
                      </label>
                    </div>
                    <div class="col-2">
                      <div class="form-check form-switch mt-2">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          v-model="serviceTemplate.groupEnabled"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3"></div>
                <div class="col-lg-3 col-sm-6"></div>
              </div>
              <div class="row" v-show="serviceTemplate.groupEnabled">
                <div class="col-lg-2">
                  <div
                    class="input-group input-group-outline mt-2"
                    :class="{ 'is-filled': serviceTemplate.groupLimit }"
                  >
                    <label class="form-label">Людей</label>
                    <input
                      type="number"
                      class="form-control"
                      v-model="serviceTemplate.groupLimit"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Card Общая информация finish -->

        <!-- Card Photo  -->
        <div class="card mt-2 mb-2" id="branding">
          <div class="card-header">
            <h6>Фото</h6>
            <div class="multisteps-form__content">
              <div class="row mt-3">
                <div
                  class="col-12"
                  v-show="
                    !serviceTemplate.photo.key && !serviceTemplate.photo.url
                  "
                >
                  <label class="form-control mb-0"
                    >Додайте фото для послуги</label
                  >
                  <div
                    class="form-control border dropzone"
                    id="servicePhoto"
                  ></div>
                </div>
                <div
                  class="col-12"
                  v-show="
                    serviceTemplate.photo.key && serviceTemplate.photo.url
                  "
                >
                  <a target="_blank" :href="serviceTemplate.photo.url">
                    <img
                      alt="service photo"
                      :src="serviceTemplate.photo.url"
                      width="200"
                    />
                  </a>
                  <a
                    class="nav-link text-danger d-flex c-width-200"
                    data-scroll=""
                    href="#"
                  >
                    <i class="material-icons text-lg me-2">delete</i>
                    <span class="text-sm" @click="removePhoto">Видалити</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body pt-0"></div>
        </div>
        <!-- Card Photo finish  -->

        <!-- Дополнительные настройки  hided since seems to be not relevant-->
        <!-- <div
          class="card mt-4 pb-3"
          id="additional-settings"
          v-if="showDevFeatures"
        >
          <div class="badge bg-gradient-info">DEV</div>
          <div class="card-header">
            <h6>Додаткові налаштування</h6>
          </div>
          <div class="container">
            <div class="row">
              <div class="col">
                <label class="text-md text-dark">Мульти-вибір часу</label>
              </div>
              <div class="col-6">
                <span class="text-sm font-weight-normal">
                  При записі клієнт зможе обрати декілька тайм-слотів часу.
                  Наприклад, при бронировані дорожки в боулингу, клієнт зможе
                  обрати 12:00 - 13:00 та 13:00 - 14:00, таким чином забронювати
                  одразу дві години для гри.</span
                >
              </div>
              <div class="col">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="serviceTemplate.multipleChoice"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- Дополнительные настройки finish -->

        <!-- Оплата онлайн hided since seems to be not relevant-->
        <!-- <div class="card mt-4 pb-3" id="payment" v-if="showDevFeatures">
          <div class="badge bg-gradient-info">DEV</div>
          <div class="card-header">
            <h6>Оплата сплата</h6>
          </div>
          <div class="container">
            <div class="row">
              <div class="col">
                <label class="text-md text-dark">Приймати сплату</label>
              </div>
              <div class="col-6">
                <span class="text-sm font-weight-normal">
                  Якщо прийом сплати увімкнено, то після вибору послуги, часу та
                  {{ getLabelStaff() }} - клієнту буде запропоновано сплатити за
                  послугу.</span
                >
              </div>
              <div class="col">
                <div class="form-check form-switch">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="serviceTemplate.onlinePaymentEnabled"
                  />
                </div>
              </div>
            </div>
          </div>
        </div> -->
        <!-- Оплата онлайн finish -->

        <!-- Card Удалить услугу -->
        <div class="card mt-4" id="delete" v-if="this.serviceTemplate._id">
          <div class="card-body">
            <div class="d-flex align-items-center mb-sm-0 mb-4">
              <div class="w-50">
                <h6>Видалити послугу</h6>
              </div>
              <div class="w-50 text-end">
                <!-- <button
                  class="btn btn-outline-secondary mb-3 mb-md-0 ms-auto"
                  type="button"
                  name="button"
                >
                  Восстановить
                </button> -->
                <button
                  class="btn bg-gradient-danger mb-0 ms-2"
                  type="button"
                  name="button"
                  data-bs-toggle="modal"
                  :data-bs-target="`#${modalParamsRemoveService.modalId}`"
                >
                  Видалити
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- Удалить услугу finish -->

        <!--  Зберегти -->
        <div class="fixed-bottom">
          <div class="row">
            <div class="col-10"></div>
            <div class="col-lg-2">
              <div class="mx-4">
                <button
                  type="button"
                  class="block btn bg-gradient-primary"
                  @click="save"
                  :disabled="!preSaveValidation()"
                >
                  Зберегти
                </button>
              </div>
            </div>
          </div>
        </div>
        <!--  Зберегти finish-->
      </div>
    </div>
  </div>

  <BmnCustomPopup :params="modalParamsRemoveService"></BmnCustomPopup>
</template>

<style scoped>
.block {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}
</style>

<style scoped>
.available {
  display: flex;
  align-items: center; /* Выравниваем элементы по центру по вертикали */
  justify-content: flex-end;
  padding-right: 16px;
}
.element {
  margin-right: 10px;
}
</style>
