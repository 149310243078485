<script setup>
import flatPickr from "vue-flatpickr-component";
import SmallLoader from "@/views/shared/SmallLoader.vue";
import {
  EVENTS_CLICK_CELL_UPDATE_MODAL,
  EVENTS_DELETE_MODAL,
  EVENTS_UPDATE_MODAL,
} from "@/utils/enums/events";
import {
  TYPE_CONSTRUCTOR_RULES,
  TYPE_CONSTRUCTOR_PRICE,
} from "@/utils/enums/typeConstructor";
import { DateTime } from "luxon";
import { computed, onMounted, ref, watch } from "vue";
import { useCompanyStore } from "@/storePinia/company";
import { useStore } from "vuex";
import { storeToRefs } from "pinia";
import { Ukrainian } from "flatpickr/dist/l10n/uk";
import { getIntervalsFromISO } from "@/utils/time";
import api from "@/services/api";
import { useModal } from "@/composables/common/modal";
import { useDaysSelection } from "@/composables/common/daysSelection";
import { v4 as uuidv4 } from "uuid";
import { useFetch } from "@/composables/common/api/useFetch";
import SchedulerConstructor from "@/components/DHTMLXCalendar/SchedulerConstructor.vue";
import RadioButtonsGroup from "@/components/common/inputs/RadioButtonsGroup.vue";
import { useStorage } from "@vueuse/core";
import cloneDeep from "lodash/cloneDeep";
import Spinner from "@/views/shared/Spinner.vue";
import CheckboxApp from "@/components/common/inputs/CheckboxApp.vue";
import InputApp from "@/components/common/inputs/InputApp.vue";
import * as _ from "lodash";
import SelectApp from "@/components/common/SelectApp.vue";

const props = defineProps({
  typeConstructor: {
    type: String,
    required: true,
  },
});

const companyStore = useCompanyStore();
const { company } = storeToRefs(companyStore);

const {
  data: dataResources,
  isLoading: isLoadingResources,
  fetchData: getResourcesApi,
} = useFetch(api.staff.list);
const {
  data: hours,
  isLoading: isLoadingHours,
  fetchData: getWorkingHoursRangeApi,
} = useFetch(api.staff.getWorkingHoursRange);

const isLoadingData = computed(() => {
  return isLoadingResources.value || isLoadingHours.value;
});

const resources = ref([]);
const events = ref([]);
const isLoading = ref(false);
const store = useCompanyStore();
const { isPrepayment } = storeToRefs(store);
const storeToast = useStore();

const showPrepaymentInputs = ref(false);

const countMonthViewItems = ref([
  {
    id: 111,
    value: 1,
    text: "1 міс.",
  },
  {
    id: 112,
    value: 3,
    text: "3 міс.",
  },
]);

const date = ref(DateTime.now().toISODate());
const dateSelected = ref("");
const dateNormalize = computed(() => {
  if (!dateSelected.value) {
    return false;
  }
  const arr = dateSelected.value.split(" ");
  if (arr.length === 3) {
    return { from: arr[0], to: arr[2] };
  } else if (arr.length > 0) {
    return { from: arr[0], to: null };
  }
  return false;
});

function updateDate(val) {
  date.value = DateTime.fromJSDate(val).toISODate();
}

watch(date, async (val) => {
  if (val) {
    await getEvents(resources.value, val);
  }
});

const countMonthView = useStorage(`${company.value?.tenantId}.countMonths`, 1);
watch(countMonthView, (val) => {
  getEvents(resources.value, date.value, val);
});

const customTitle = ref("");

onMounted(async () => {
  await getResources();
  await getEvents(resources.value, date.value);
});

function getRangeDate(date, countMonth) {
  return {
    dateFrom: DateTime.fromISO(date).startOf("month").toISODate(),
    dateTo: DateTime.fromISO(date)
      .startOf("month")
      // .endOf("month")
      .plus({ months: countMonth })
      .toISODate(),
  };
}

const currentEventUser = ref(null);

const optionsSelect = computed(() => {
  if (!dataResources.value) {
    return [];
  }

  let resultArr = [];
  dataResources.value.forEach((item) => {
    let categoryIndex = resultArr.findIndex(
      (category) => category.categoryId === item.positionId._id,
    );
    const roomItem = {
      name: `${item.label} (гостей:${item.quest})`,
      code: item.key,
      quest: item.quest,
    };

    if (categoryIndex >= 0) {
      resultArr[categoryIndex].rooms.push(roomItem);
    } else {
      const categoryName = item.positionId.name;
      const categoryId = item.positionId._id;
      const rank = item.positionId.rank;

      const itemWithCategory = {
        categoryName,
        categoryId,
        rank,
        rooms: [roomItem],
      };
      resultArr.push(itemWithCategory);
    }
  });

  resultArr = _.sortBy(resultArr, "rank");

  return resultArr;
});

const selectedRooms = ref([]);

const isValidateOptionsSelect = computed(() => {
  if (!selectedRooms.value) {
    return false;
  }
  const valuesArray = selectedRooms.value.map((item) => item.quest);
  const uniqueValues = new Set(valuesArray);

  return uniqueValues.size === 1;
});

const questData = ref([]);
const countQuests = computed(() => {
  if (!selectedRooms.value || selectedRooms.value.length === 0) {
    return 1;
  }
  const arrayQuest = selectedRooms.value?.map((item) => item.quest);
  return Math.max(...arrayQuest);
});
watch(
  countQuests,
  (newValue) => {
    questData.value = [];
    for (let i = 1; i <= newValue; i++) {
      questData.value.push({
        id: uuidv4(),
        upToGuestsNumber: i,
        price: null,
        prepaymentAmount: null,
      });
    }
  },
  { immediate: true },
);

const selectedMinDays = ref();
const checkInNotAllowed = ref();
const checkOutNotAllowed = ref();
const optionsSelectCountMinDays = computed(() => {
  const arr = Array.from({ length: 10 }, (_, i) => i + 1).map((item) => ({
    name: item,
    code: item,
  }));
  return arr;
});

const configFlatpickr = ref({
  locale: { ...Ukrainian, rangeSeparator: " по " },
  mode: "range",
  altFormat: "M j, Y",
  conjunction: "11",
  altInput: true,
  static: true,
  inline: true,
  clickOpens: false,
});

const { allDay, selectedDays, days } = useDaysSelection();

async function saveWorkingDays(isDeleted = false) {
  if (!isValidateOptionsSelect.value && selectedRooms.value.length === 0) {
    return;
  }
  try {
    isLoading.value = true;
    const promises = selectedRooms.value.map((item) => {
      return saveWorkingHoursForRoom(item.code, isDeleted);
    });

    Promise.all(promises)
      .then(() => {
        reset();
        storeToast.commit("addToast", { title: "Збережено" });
        childComponentRef.value.updateScheduler();
        closeModalThis();
        getEvents(resources.value, date.value);
      })
      .catch(() => {
        storeToast.commit("addToast", {
          title: "Виникла помилка. Спробуйте ще раз",
          type: "error",
        });
      });
  } catch (error) {
    storeToast.commit("addToast", {
      title: "Виникла помилка. Спробуйте ще раз",
      type: "error",
    });
  } finally {
    isLoading.value = false;
  }
}

async function saveWorkingHoursForRoom(staffId, isDeleted = false) {
  const days = [];
  const intervals = getIntervalsFromISO(
    dateNormalize.value.from,
    dateNormalize.value.to || dateNormalize.value.from,
  );
  intervals.forEach((item) => {
    const iteration = DateTime.fromISO(item);
    const dateIsInSelected =
      selectedDays.value.findIndex(
        (itemSelected) => itemSelected.number === iteration.weekday,
      ) >= 0;
    if (!dateIsInSelected) {
      return;
    }
    if (props.typeConstructor === TYPE_CONSTRUCTOR_PRICE) {
      if (isDeleted) {
        clearData();
      }
      days.push({
        isActive: !isDeleted,
        date: item,
        pricingPerGuest: questData.value.map((item) => {
          delete item.id;
          return item;
        }),
      });
    } else if (props.typeConstructor === TYPE_CONSTRUCTOR_RULES) {
      const findEl = events.value.find(
        (itemFind) =>
          itemFind.resourceId === staffId && item === itemFind.start,
      );

      const findElStatus = findEl ? findEl.isActive : true;

      const rules = {
        minDays: isDeleted ? 1 : selectedMinDays.value?.code,
      };

      if (!isDeleted && checkInNotAllowed.value) {
        rules.checkInNotAllowed = checkInNotAllowed.value;
      }

      if (!isDeleted && checkOutNotAllowed.value) {
        rules.checkOutNotAllowed = checkOutNotAllowed.value;
      }

      days.push({
        isActive: findElStatus,
        date: item,
        rules,
      });
    }
  });

  const payload = {
    staffId,
    days,
  };
  await api.staff.constructorSaveWorkingHours(staffId, payload);
}

function clearData() {
  selectedMinDays.value = null;
  checkInNotAllowed.value = false;
  checkOutNotAllowed.value = false;
  questData.value = questData.value.map((item) => ({
    ...item,
    price: null,
    prepaymentAmount: null,
  }));
}

function reset() {
  dateSelected.value = "";
  selectedRooms.value = [];
  allDay.value = true;
  selectedMinDays.value = null;
  checkInNotAllowed.value = false;
  checkOutNotAllowed.value = false;
}

const {
  openModal: openModalThis,
  closeModal: closeModalThis,
  modalRef,
} = useModal();

const isValid = computed(() => {
  if (currentEventUser.value === EVENTS_DELETE_MODAL) {
    return (
      dateSelected.value &&
      selectedRooms.value.length > 0 &&
      (selectedDays.value.length > 0 || allDay.value)
    );
  }
  if (props.typeConstructor === TYPE_CONSTRUCTOR_PRICE) {
    return (
      dateSelected.value &&
      questData.value.some((item) => item.price) &&
      selectedRooms.value.length > 0 &&
      (selectedDays.value.length > 0 || allDay.value)
    );
  }
  if (props.typeConstructor === TYPE_CONSTRUCTOR_RULES) {
    return (
      dateSelected.value &&
      selectedMinDays.value &&
      selectedRooms.value.length > 0 &&
      (selectedDays.value.length > 0 || allDay.value)
    );
  }

  return false;
});

const isVisibleTextDanger = computed(() => {
  return (
    !isValidateOptionsSelect.value &&
    selectedRooms.value.length > 0 &&
    currentEventUser.value === EVENTS_UPDATE_MODAL &&
    props.typeConstructor === TYPE_CONSTRUCTOR_PRICE
  );
});

async function getResources() {
  const params = {
    isActive: "1",
  };
  await getResourcesApi([params]);
  resources.value = dataResources.value;
  dataResources.value = dataResources.value.map((item) => {
    return {
      key: item._id,
      label: item.name,
      rank: item.rank,
      positionId: item.positionId,
      quest: item.checkinRules.guestsMax,
    };
  });
}

async function getEvents(
  resourcesList = [],
  date,
  countMonth = countMonthView.value,
) {
  const { dateFrom, dateTo } = getRangeDate(date, countMonth);

  const payload = {
    startDate: dateFrom,
    endDate: dateTo,
  };
  let tempEventsList = await Promise.all(
    resourcesList.map(async (item) => {
      await getWorkingHoursRangeApi([item._id, payload]);
      const result = hours.value;
      if (!result) {
        return [];
      }

      return [...result].map((itemDay) => {
        return {
          item_id: item._id,
          isActive: itemDay.isActive,
          start_date: itemDay.date,
          end_date: itemDay.date,
          prices: itemDay.pricingPerGuest,
          minDays: itemDay.rules?.minDays,
          checkInNotAllowed: itemDay.rules?.checkInNotAllowed,
          checkOutNotAllowed: itemDay.rules?.checkOutNotAllowed,
          isSomePrice: itemDay.pricingPerGuest?.some((item) => item.price),
        };
      });
    }),
  );

  const eventsList = tempEventsList.flat();
  events.value = cloneDeep(eventsList);
}

const childComponentRef = ref();

function updateEvent(id, dateStrStart) {
  const room = dataResources.value.find((item) => item.key === id);

  selectedRooms.value = [
    {
      code: room.key,
      name: room.label,
      quest: room.quest,
    },
  ];
  dateSelected.value = DateTime.fromISO(dateStrStart).toISODate();
  const dateValue = DateTime.fromISO(dateStrStart)
    .setLocale("uk-UA")
    .toFormat("dd.MM (ccc)");
  customTitle.value = room.label + " " + dateValue;

  const findEvent = events.value.find((item) => {
    return (
      DateTime.fromJSDate(item.start_date).toISODate() === dateStrStart &&
      item.item_id === id
    );
  });

  if (props.typeConstructor === TYPE_CONSTRUCTOR_RULES) {
    checkInNotAllowed.value = findEvent.checkInNotAllowed;
    checkOutNotAllowed.value = findEvent.checkOutNotAllowed;

    selectedMinDays.value = {
      name: findEvent?.minDays || 1,
      code: findEvent?.minDays || 1,
    };
  }
  if (props.typeConstructor === TYPE_CONSTRUCTOR_PRICE) {
    setTimeout(() => {
      questData.value = [];
      const isValidPrices = findEvent?.prices.find((item) => item.price);
      for (let i = 1; i <= room.quest; i++) {
        questData.value.push({
          id: uuidv4(),
          upToGuestsNumber: i,
          price: null,
          prepaymentAmount: null,
        });
      }

      if (isValidPrices) {
        findEvent.prices.forEach((item) => {
          if (item.price) {
            const index = questData.value.findIndex(
              (itemEl) => itemEl.upToGuestsNumber === item.upToGuestsNumber,
            );
            questData.value[index] = item;
          }
        });
      }
    }, 100);
  }

  openModal(EVENTS_CLICK_CELL_UPDATE_MODAL);
}

const priceFromGuest = ref(false);

const showInputs = computed(() => {
  return (
    (currentEventUser.value === EVENTS_UPDATE_MODAL ||
      currentEventUser.value === EVENTS_CLICK_CELL_UPDATE_MODAL) &&
    props.typeConstructor === TYPE_CONSTRUCTOR_PRICE
  );
});

const datePeriodOptions = [
  {
    label: "Поточний місяць (від сьогодні)",
    key: "currentMonth",
  },
  {
    label: "Наступний місяць",
    key: "nextMonth",
  },
  {
    label: "Поточний рік (від сьогодні)",
    key: "currentYear",
  },
  {
    label: "Наступний рік",
    key: "nextYear",
  },
  {
    label: "Обрати дати",
    key: "selectRange",
  },
];

const datePeriod = ref(datePeriodOptions[0]);
const isDateRange = ref(false);
const flatpickrRef = ref(null);

watch(
  datePeriod,
  () => {
    isDateRange.value = false;
    const now = DateTime.now();

    if (datePeriod.value.key === "currentMonth") {
      dateSelected.value =
        now.toISODate() + " по " + now.endOf("month").toISODate();
    } else if (datePeriod.value.key === "nextMonth") {
      const nextMonth = now.plus({ months: 1 });
      dateSelected.value =
        nextMonth.startOf("month").toISODate() +
        " по " +
        nextMonth.endOf("month").toISODate();
    } else if (datePeriod.value.key === "currentYear") {
      dateSelected.value =
        now.toISODate() + " по " + now.endOf("year").toISODate();
    } else if (datePeriod.value.key === "nextYear") {
      const nextYear = now.endOf("year").plus({ years: 1 }).startOf("year");
      dateSelected.value =
        nextYear.toISODate() + " по " + nextYear.endOf("year").toISODate();
    } else if (datePeriod.value.key === "selectRange") {
      dateSelected.value = "";
      isDateRange.value = true;
      flatpickrRef.value.fp.open();
    }
  },
  { immediate: true },
);

function openModal(typeEvent) {
  currentEventUser.value = typeEvent;

  if (typeEvent === EVENTS_UPDATE_MODAL || typeEvent === EVENTS_DELETE_MODAL) {
    datePeriod.value = datePeriodOptions[0];
  }

  if (typeEvent !== EVENTS_CLICK_CELL_UPDATE_MODAL) {
    selectedRooms.value = [];
  }

  openModalThis();
}

function closeModal() {
  closeModalThis();
  reset();
}
</script>

<template>
  <div class="wrap mb-3 mt-3">
    <div class="card-calendar">
      <div class="card-body">
        <div
          class="d-flex row row-cols-md-auto flex-wrap flex-md-nowrap justify-content-between mb-3"
        >
          <div class="col-12 mb-2 mb-md-0 col-md-auto">
            <button
              class="btn btn-sm bg-gradient-primary w-100 mb-0 h-100"
              type="button"
              @click="openModal(EVENTS_UPDATE_MODAL)"
            >
              <span v-if="typeConstructor === TYPE_CONSTRUCTOR_PRICE">
                Встановити ціни
              </span>

              <span v-else-if="typeConstructor === TYPE_CONSTRUCTOR_RULES">
                Встановити обмеження
              </span>

              <span v-else> Встановити </span>
            </button>
          </div>

          <div class="col-8 col-md-auto">
            <RadioButtonsGroup
              v-model="countMonthView"
              :items="countMonthViewItems"
            />
          </div>

          <div class="row col-4 col-md-auto row-cols-md-auto p-0 m-0">
            <div class="col col-md-auto">
              <button
                class="btn btn-sm btn-outline-danger flex-grow-1 mb-0 h-100 w-100 flex-md-grow-0"
                type="button"
                @click="openModal(EVENTS_DELETE_MODAL)"
              >
                видалити
              </button>
            </div>
          </div>
        </div>

        <div class="scheduler-wrap">
          <Spinner :loaded="!isLoadingData"></Spinner>
          <SchedulerConstructor
            ref="childComponentRef"
            :class="{ loading: isLoadingData }"
            :count-month-view="countMonthView"
            :date="date"
            :events="events"
            :items="dataResources"
            :type-constructor="typeConstructor"
            @update-date="updateDate"
            @update-event="updateEvent"
          />
        </div>
      </div>
    </div>
  </div>

  <!-- Modal -->
  <div
    id="exampleModalConstructor"
    ref="modalRef"
    aria-hidden="true"
    aria-labelledby="exampleModalLabel"
    class="modal fade"
    role="dialog"
    tabindex="-1"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h5
            v-if="currentEventUser === EVENTS_CLICK_CELL_UPDATE_MODAL"
            class="modal-title font-weight-normal"
          >
            {{ customTitle }}
          </h5>

          <h5
            v-if="currentEventUser === EVENTS_UPDATE_MODAL"
            class="modal-title font-weight-normal"
          >
            <span v-if="typeConstructor === TYPE_CONSTRUCTOR_PRICE">
              Встановити ціни
            </span>

            <span v-if="typeConstructor === TYPE_CONSTRUCTOR_RULES">
              Встановити обмеження
            </span>
          </h5>

          <h5
            v-if="currentEventUser === EVENTS_DELETE_MODAL"
            class="modal-title font-weight-normal"
          >
            Видалення
          </h5>
          <button
            aria-label="Close"
            class="btn-close text-dark"
            type="button"
            @click="closeModal"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div
            v-if="currentEventUser !== EVENTS_CLICK_CELL_UPDATE_MODAL"
            class="input-group input-group-static"
          >
            <label class="ms-0" for="exampleFormControlSelect2"
              >Оберіть номери (можна декілька):</label
            >
            <label v-if="isVisibleTextDanger" class="text-danger"
              >Будь ласка, оберіть номери з однаковою кількістю гостей
            </label>
            <SelectApp
              v-model="selectedRooms"
              :settings="{
                taggable: true,
                multiple: true,
                closeOnSelect: false,
                options: optionsSelect,
                trackBy: 'code',
                label: 'name',
                placeholder: 'Оберіть номери',
                groupSelect: true,
                groupLabel: 'categoryName',
                groupValues: 'rooms',
              }"
            />
          </div>
          <div
            v-if="
              props.typeConstructor === TYPE_CONSTRUCTOR_RULES &&
              (currentEventUser === EVENTS_UPDATE_MODAL ||
                currentEventUser === EVENTS_CLICK_CELL_UPDATE_MODAL)
            "
            class="input-group input-group-static mb-4 mt-4"
          >
            <label class="ms-0" for="exampleFormControlSelect2"
              >Оберіть мінімальну кількість ночей:</label
            >
            <SelectApp
              v-model="selectedMinDays"
              :settings="{
                options: optionsSelectCountMinDays,
                trackBy: 'code',
                label: 'name',
                placeholder: 'Оберіть кількість ночей',
              }"
            />
            <div class="input-group input-group-static">
              <div class="form-check">
                <input
                  id="checkInNotAllowed"
                  v-model="checkInNotAllowed"
                  class="form-check-input"
                  type="checkbox"
                />
                <label class="custom-control-label ms-1" for="checkInNotAllowed"
                  >Заборонити заїзд</label
                >
              </div>
            </div>

            <div class="input-group input-group-static">
              <div class="form-check">
                <input
                  id="checkOutNotAllowed"
                  v-model="checkOutNotAllowed"
                  class="form-check-input"
                  type="checkbox"
                  value=""
                />
                <label
                  class="custom-control-label ms-1"
                  for="checkOutNotAllowed"
                  >Заборонити виїзд</label
                >
              </div>
            </div>
          </div>
          <CheckboxApp
            v-if="showInputs"
            v-model="priceFromGuest"
            class="mt-3"
            label="ціна залежить від кількості гостей"
          />
          <CheckboxApp
            class="mb-3"
            v-if="showInputs && isPrepayment"
            v-model="showPrepaymentInputs"
            label="встановити передплату"
          />
          <template v-if="showInputs && priceFromGuest">
            <div v-for="item of questData" :key="item.id" class="">
              <label> Гостей:{{ item.upToGuestsNumber }} </label>
              <InputApp
                v-model="item.price"
                :disabled="!isValidateOptionsSelect"
                class="mb-2"
                placeholder="Ціна грн"
                type="tel"
              />
              <InputApp
                v-if="isPrepayment && showPrepaymentInputs"
                v-model="item.prepaymentAmount"
                :disabled="!isValidateOptionsSelect"
                placeholder="Ціна передоплати грн"
                type="tel"
              />
            </div>
          </template>
          <template v-if="showInputs && !priceFromGuest">
            <label class="ms-0">Ціна</label>
            <InputApp
              v-model="questData[questData.length - 1].price"
              :disabled="!isValidateOptionsSelect"
              class="mb-2"
              placeholder="0 грн"
              type="tel"
            />
            <InputApp
              v-if="isPrepayment && showPrepaymentInputs"
              v-model="questData[questData.length - 1].prepaymentAmount"
              :disabled="!isValidateOptionsSelect"
              placeholder="Ціна передоплати грн"
              type="tel"
            />
          </template>
          <div
            v-if="currentEventUser !== EVENTS_CLICK_CELL_UPDATE_MODAL"
            class="input-group input-group-static"
          >
            <label class="mt-2">Оберіть період</label>

            <SelectApp
              v-model="datePeriod"
              :settings="{
                options: datePeriodOptions,
                showLabels: true,
                label: 'label',
                trackBy: 'key',
                placeholder: 'Оберіть період',
              }"
            />

            <div v-show="isDateRange" class="date-range-calendar w-100">
              <flat-pickr
                ref="flatpickrRef"
                v-model="dateSelected"
                :config="configFlatpickr"
                :type="'range'"
                class="form-control datetimepicker text-xs"
                placeholder="Оберіть дату"
              />
            </div>
          </div>
          <div
            v-if="currentEventUser !== EVENTS_CLICK_CELL_UPDATE_MODAL"
            class=""
          >
            <label class="mt-2">Оберіть дні тижня:</label>
            <CheckboxApp v-model="allDay" label="Усі дні" />

            <CheckboxApp
              v-for="item of days"
              :key="item.number"
              v-model="item.isActive"
              :label="item.label"
            />
          </div>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-outline-secondary"
            type="button"
            @click="closeModal"
          >
            закрити
          </button>
          <button
            v-if="currentEventUser === EVENTS_CLICK_CELL_UPDATE_MODAL"
            :disabled="isLoading"
            class="btn btn-outline-danger"
            type="button"
            @click="saveWorkingDays(true)"
          >
            <SmallLoader v-if="isLoading" />
            <span v-else> Видалити</span>
          </button>
          <button
            v-if="
              currentEventUser === EVENTS_UPDATE_MODAL ||
              currentEventUser === EVENTS_CLICK_CELL_UPDATE_MODAL
            "
            :disabled="isLoading || !isValid"
            class="btn bg-gradient-primary"
            type="button"
            @click="saveWorkingDays()"
          >
            <SmallLoader v-if="isLoading" />
            <span v-else> Зберегти</span>
          </button>
          <button
            v-if="currentEventUser === EVENTS_DELETE_MODAL"
            :disabled="isLoading || !isValid"
            class="btn bg-gradient-primary"
            type="button"
            @click="saveWorkingDays(true)"
          >
            <SmallLoader v-if="isLoading" />
            <span v-else> Видалити</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal -->
</template>

<style lang="scss" scoped>
.form-check {
  padding-left: 0;
}

.certsum {
  max-width: 50%;
}

.btn-outline-primary {
  cursor: pointer;
}

.btn-outline-primary:hover:not(.active):hover {
  opacity: 1 !important;
}

@media (hover: hover) {
  .btn-outline-primary:hover:not(.active):hover {
    background-color: transparent;
    box-shadow: none;
    color: #0f4432;
    opacity: 0.7 !important;
  }
}

.btn-check:checked + .btn-outline-primary {
  color: #fff !important;
  background-color: #0f4432 !important;
  border-color: #0f4432 !important;
}

:deep(.fc-timeline-event p) {
  font-size: 10.2px !important;
}

.card-calendar {
  background: #fff;
}

.material-symbols-outlined.small {
  font-size: 12px;
}

.material-symbols-outlined.big {
  font-size: 20px;
}

.scheduler-wrap {
  position: relative;
  overflow: hidden;

  .loading {
    opacity: 0.6;
    pointer-events: none;
  }

  .main {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;
  }

  & .loader-container {
    position: absolute;
    margin-top: 0;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.date-range-calendar {
  :deep(.flatpickr-wrapper) {
    width: 100%;
  }
}
</style>
