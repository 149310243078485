<template>
  <div v-show="!dataLoaded" class="container-fluid">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div v-show="dataLoaded" class="container-fluid">
    <div class="row mt-3">
      <div class="col-8"></div>
      <div class="col-lg-4 balance text-end">
        <span class="text-bold"> Баланс: </span
        ><span class=""> {{ company.smsBalance }} СМС </span>
      </div>
    </div>
    <div class="row">
      <div id="comments" class="card">
        <div class="card-body pt-0 mt-2">
          <div id="nav-tabContent" class="tab-content">
            <h3>SMS Шаблони</h3>
            <div
              id="auto-sms"
              aria-labelledby="nav-profile-tab"
              class="tab-pane fade active show"
              role="tabpanel"
            >
              <div class="card-body p-1">
                <div class="row">
                  <div class="col-lg-6">
                    <h6>Шаблон нагадування</h6>
                    <div class="row infoBlock">
                      <div class="d-flex align-items-center gap-2">
                        <span class="material-symbols-outlined"> info </span>
                        <label
                          v-if="company.crmMode === 'hotel'"
                          class="text-xs"
                          style="color: #458466"
                        >
                          Нагадування відправляються в визначений в
                          Налаштуваннях час. <br />
                          Приклад:<br />
                          В налаштуваннях встановлено відправка за 24г до
                          заїзду<br />
                          Бронювання Гостя на 7 січня, час заїзду 15:00<br />
                          Нагадування буде відправлено 6 січня о 15:00.
                        </label>
                        <label
                          v-if="company.crmMode !== 'hotel'"
                          class="text-xs"
                        >
                          Нагадування відправляються в визначений в
                          Налаштуваннях час. <br />
                          Приклад:<br />
                          В налаштуваннях встановлено відправка за 24г до
                          запису<br />
                          Запис Клієнта на 7 січня на 15:00<br />
                          Нагадування буде відправлено 6 січня о 15:00.
                        </label>
                      </div>
                    </div>
                    <div
                      v-if="company.crmMode !== 'hotel'"
                      class="input-group input-group-outline"
                    >
                      <textarea
                        v-model="company.smsTextTemplate"
                        class="form-control"
                        placeholder="Ви записанi о {13:00 23.06.2022}. {телефон_филиала}."
                        rows="2"
                        type="text"
                        @input="autoResizeTextarea"
                        ref="descriptionRef1"
                        style="height: 75px"
                      />
                    </div>
                    <div
                      v-if="company.crmMode === 'hotel'"
                      class="input-group input-group-outline"
                    >
                      <textarea
                        v-model="company.smsTextTemplate"
                        class="form-control"
                        placeholder="Шановний Гість! Ви забронювали {назва_номеру} на {дата_заїзду}{перенос_строки}{телефон_филиала}"
                        rows="2"
                        type="text"
                        @input="autoResizeTextarea"
                        ref="descriptionRef2"
                        style="height: 75px"
                      />
                    </div>
                    <b class="text-xs">Змінні:</b>
                    <p v-if="company.crmMode !== 'hotel'" class="text-xs">
                      {номер_броні}, {время_и_дата}, {имя_клиента},
                      {{ getLabelStaffPlaceholder() }}, {посада},
                      {телефон_филиала}, {посилання_на_оплату},
                      {перенос_строки}, {сума_до_сплати}
                    </p>
                    <p v-if="company.crmMode === 'hotel'" class="text-xs">
                      {номер_броні}, {дата_заїзду}, {дата_виїзду},
                      {имя_клиента}, {назва_номеру}, {категорія},
                      {телефон_филиала}, {посилання_на_оплату},
                      {перенос_строки}, {код_доступу}, {кількість_дорослих},
                      {кількість_дітей}, {кількість_діб}, {сума_до_сплати}
                    </p>
                  </div>
                  <div class="col-lg-6">
                    <h6 v-if="company.crmMode !== 'hotel'" class="">
                      Шаблон SMS підтвердження про запис
                    </h6>
                    <h6 v-if="company.crmMode === 'hotel'" class="">
                      Шаблон про створене бронювання
                    </h6>
                    <div class="row infoBlock">
                      <div class="d-flex align-items-center gap-2">
                        <span class="material-symbols-outlined"> info </span>
                        <label
                          v-if="company.crmMode === 'hotel'"
                          class="text-xs"
                          style="color: #458466"
                        >
                          Шаблон відправляється щойно бронювання було створено.
                          Під час створення бронювання в ньому має бути
                          зазначено клієнта з номером телефону. В іншому випадку
                          підтвердження відправлено не буде, навіть якщо ви
                          відредагуєте бронювання вказавши йому клієнта з
                          телефоном.
                        </label>
                        <label
                          v-if="company.crmMode !== 'hotel'"
                          class="text-xs"
                        >
                          Шаблон з підтвердженням відправляється щойно Запис
                          було створено. Під час створення Запису в ньому має
                          бути зазначено клієнта з номером телефону. В іншому
                          випадку підтвердження відправлено не буде, навіть якщо
                          ви відредагуєте запис вказавши йому клієнта з
                          телефоном.
                        </label>
                      </div>
                    </div>
                    <div
                      v-if="company.crmMode !== 'hotel'"
                      class="input-group input-group-outline"
                    >
                      <textarea
                        v-model="company.smsNotifyNewServiceOrderTemplate"
                        :placeholder="`Вас записано о {13:00 23.06.2022}: ${getLabelStaffPlaceholder()}. {перенос_строки}{телефон_филиала}.`"
                        class="form-control"
                        rows="2"
                        type="text"
                        @input="autoResizeTextarea"
                        ref="descriptionRef3"
                        style="height: 75px"
                      />
                    </div>
                    <div
                      v-if="company.crmMode === 'hotel'"
                      class="input-group input-group-outline"
                    >
                      <textarea
                        v-model="company.smsNotifyNewServiceOrderTemplate"
                        :placeholder="`Ви забронювали {назва_номеру} на {дата_заїзду}{перенос_строки}{телефон_филиала}`"
                        class="form-control"
                        rows="2"
                        type="text"
                        @input="autoResizeTextarea"
                        ref="descriptionRef4"
                        style="height: 75px"
                      />
                    </div>
                    <b class="text-xs">Змінні:</b>
                    <p v-if="company.crmMode !== 'hotel'" class="text-xs">
                      {номер_броні}, {время_и_дата}, {имя_клиента},
                      {{ getLabelStaffPlaceholder() }}, {посада},
                      {телефон_филиала}, {посилання_на_оплату},
                      {перенос_строки}, {сума_до_сплати}
                    </p>
                    <p v-if="company.crmMode === 'hotel'" class="text-xs">
                      {номер_броні}, {дата_заїзду}, {дата_виїзду},
                      {имя_клиента}, {назва_номеру}, {категорія},
                      {телефон_филиала}, {посилання_на_оплату},
                      {перенос_строки}, {код_доступу}, {кількість_дорослих},
                      {кількість_дітей}, {кількість_діб}, {сума_до_сплати}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-6">
                    <h6>Відправлення інвойсу</h6>

                    <div class="row infoBlock">
                      <div class="d-flex align-items-center gap-2">
                        <span class="material-symbols-outlined"> info </span>
                        <label class="text-xs" style="color: #458466">
                          SMS з інвойсом на оплату відправляється вручну
                          адміністратором при додаванні оплати до бронювання.
                          Для відправлення цього повідомлення в Вашій компанії
                          повинен бути підключений еквайрінг. Також в ньому має
                          бути зазначений номер телефону клієнта.
                        </label>
                      </div>
                    </div>

                    <div class="input-group input-group-outline">
                      <textarea
                        v-model="company.smsInvoiceTemplate"
                        :placeholder="smsInvoiceTemplatePlaceholder"
                        class="form-control"
                        rows="2"
                        type="text"
                        @input="autoResizeTextarea"
                        ref="descriptionRef4"
                        style="height: 75px"
                      />
                    </div>
                    <b class="text-xs">Змінні:</b>
                    <p v-if="company.crmMode !== 'hotel'" class="text-xs">
                      {номер_броні}, {время_и_дата}, {имя_клиента},
                      {{ getLabelStaffPlaceholder() }}, {посада},
                      {телефон_филиала}, {посилання_на_оплату},
                      {сума_до_сплати}, {перенос_строки}
                    </p>
                    <p v-if="company.crmMode === 'hotel'" class="text-xs">
                      {номер_броні}, {дата_заїзду}, {дата_виїзду},
                      {имя_клиента}, {назва_номеру}, {категорія},
                      {телефон_филиала}, {посилання_на_оплату},
                      {перенос_строки}, {код_доступу}, {кількість_дорослих},
                      {кількість_дітей}, {кількість_діб}, {сума_до_сплати}
                    </p>
                  </div>

                  <div class="col-lg-6">
                    <h6>Відправлення SMS про успішну оплату</h6>

                    <div class="row infoBlock">
                      <div class="d-flex align-items-center gap-2">
                        <span class="material-symbols-outlined"> info </span>
                        <label class="text-xs" style="color: #458466">
                          SMS з повідомленням про успішну оплату відправляється
                          Гостю автоматично після того, як платіж буде оброблено
                          банком. Для відправлення повідомлення в Вашій компанії
                          повинен бути підключений еквайрінг. Також в бронюванні
                          має бути зазначений номер телефону клієнта.
                        </label>
                      </div>
                    </div>

                    <div class="input-group input-group-outline">
                      <textarea
                        v-model="company.smsOnSuccessfulPaymentTemplate"
                        :placeholder="smsOnSuccessfulPaymentPlaceholder"
                        class="form-control"
                        rows="2"
                        type="text"
                        @input="autoResizeTextarea"
                        ref="descriptionRef4"
                        style="height: 75px"
                      />
                    </div>
                    <b class="text-xs">Змінні:</b>
                    <p v-if="company.crmMode !== 'hotel'" class="text-xs">
                      {номер_броні}, {время_и_дата}, {имя_клиента},
                      {{ getLabelStaffPlaceholder() }}, {посада},
                      {телефон_филиала}, {посилання_на_оплату},
                      {сума_до_сплати}, {перенос_строки}
                    </p>
                    <p v-if="company.crmMode === 'hotel'" class="text-xs">
                      {номер_броні}, {дата_заїзду}, {дата_виїзду},
                      {имя_клиента}, {назва_номеру}, {категорія},
                      {телефон_филиала}, {посилання_на_оплату},
                      {перенос_строки}, {код_доступу}, {кількість_дорослих},
                      {кількість_дітей}, {кількість_діб}, {сума_до_сплати}
                    </p>
                  </div>
                </div>

                <div class="w-50">
                  <button
                    class="btn bg-gradient-primary"
                    type="button"
                    @click="save"
                  >
                    Зберегти
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import Spinner from "@/views/shared/Spinner.vue";

import api from "@/services/api";
import constants from "@/constants";

import { autoResizeTextarea } from "@/components/common/functions/autoResizeTextarea";

import { onBeforeMount, onMounted, ref } from "vue";
import { useStore } from "vuex";

const dataLoaded = ref(false);

const descriptionRef1 = ref(null);
const descriptionRef2 = ref(null);
const descriptionRef3 = ref(null);
const descriptionRef4 = ref(null);

const store = useStore();

const smsInvoiceTemplatePlaceholder = ref("");
const smsOnSuccessfulPaymentPlaceholder = ref("");

const company = ref({
  crmMode: "",
  localizationMode: "",
  smsBalance: 0,
  smsSenderName: "",
  smsTextTemplate: "",
  smsNotifyNewServiceOrderTemplate: "",
  smsInvoiceTemplate: "",
  smsOnSuccessfulPaymentTemplate: "",
});

onBeforeMount(async () => {
  company.value = await api.company.show();

  smsInvoiceTemplatePlaceholder.value =
    company.value.crmMode === "hotel"
      ? "Посилання на оплату Вашого бронювання на суму {сума_до_сплати} грн: {посилання_на_оплату}"
      : "Посилання на оплату Вашої послуги на суму {сума_до_сплати} грн: {посилання_на_оплату}";
  smsOnSuccessfulPaymentPlaceholder.value =
    "Ваша оплата на суму {сума_до_сплати} грн пройшла успішно! Дякуємо!";

  dataLoaded.value = true;
});

onMounted(() => {
  setTimeout(() => {
    autoResizeTextarea({
      target: descriptionRef1.value,
    });
    autoResizeTextarea({
      target: descriptionRef2.value,
    });
    autoResizeTextarea({
      target: descriptionRef3.value,
    });
    autoResizeTextarea({
      target: descriptionRef4.value,
    });
  }, 1000);
});

async function save() {
  const payload = {
    smsTextTemplate: company.value.smsTextTemplate,
    smsNotifyNewServiceOrderTemplate:
      company.value.smsNotifyNewServiceOrderTemplate,
    smsInvoiceTemplate: company.value.smsInvoiceTemplate,
    smsOnSuccessfulPaymentTemplate:
      company.value.smsOnSuccessfulPaymentTemplate,
  };

  try {
    await api.company.update(payload);
    store.commit("addToast", {
      title: "Збережено",
    });
  } catch (error) {
    store.commit("addToast", {
      title: "Виникла помилка. Спробуйте ще раз",
      type: "error",
    });
  }
}

function getLabelStaffPlaceholder() {
  const localizationMode = company.value?.localizationMode;
  if (!localizationMode) {
    return;
  }

  switch (true) {
    case localizationMode === "spa":
      return "{назва_ресурсу}";
    case localizationMode === "hotel":
      return "{назва_номеру}";
    default:
      return "{имя_мастера}";
  }
}
</script>

<style scoped>
.infoBlock {
  color: #458466;
  background-color: #deede3;
  border-radius: 8px;
  margin-bottom: 16px;
  margin-left: 4px;
  margin-right: 4px;
  padding: 12px 12px 12px 12px;
}
.container-fluid {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}
.card {
  border-radius: 0px;
}
.balance {
  padding-right: 16px;
}
</style>
