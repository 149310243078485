import loadImage from "blueimp-load-image";

export function useFile() {
  function rotateImage(file, done) {
    loadImage(
      file,
      (canvas) => {
        canvas.toBlob((blob) => {
          done(blob);
        }, file.type);
      },
      { orientation: true, canvas: true },
    );
  }

  return { rotateImage };
}
