<template>
  <div class="container-fluid my-3 py-3">
    <div class="row mb-5">
      <div class="col-lg-10 mt-lg-0 mx-auto">
        <!-- widget Info -->
        <div class="card" id="widget">
          <div class="card-header">
            <h2>API</h2>
          </div>

          <div class="card-body pt-0">
            Найостанніша версія АРІ доступна за посиланням: <br />
            <a
              href="https://bookmenow.pro/wp-content/uploads/2025/03/SD-External-API-270325-074814.pdf"
              target="_blank"
              >Завантажити</a
            >
            <br />
            <br />
            Дата публікації: 27.03.2025
          </div>
        </div>
        <!-- widget finish -->
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.code-style {
  color: #d63384;
}
</style>
