<template>
  <div class="py-4 container-fluid" v-show="!dataLoaded">
    <Spinner :loaded="dataLoaded"></Spinner>
  </div>
  <div class="container-fluid new-service-order-page" v-show="dataLoaded">
    <div class="row">
      <div class="personalSwitcher" v-if="mode === 'create'">
        <div class="element" v-if="company.crmMode !== 'hotel'">
          Особиста подія
        </div>
        <div class="element" v-if="company.crmMode === 'hotel'">
          Закрити дати
        </div>
        <div class="form-check form-switch">
          <input
            class="form-check-input"
            type="checkbox"
            v-model="isPersonal"
            @change="onIsPersonalChange"
            :disabled="mode === 'edit'"
          />
        </div>
      </div>
      <div
        class="badge bg-gradient-dark w-100 label-personal"
        v-if="mode === 'edit' && orderTemplate.status === 'personal'"
      >
        <span v-if="company.crmMode !== 'hotel'"> Особиста</span>
        <span v-if="company.crmMode === 'hotel'"> Закрита дата</span>
      </div>

      <div class="col-3"></div>
    </div>
    <div class="row">
      <div class="nav-wrapper position-relative end-0 mt-3" v-if="!isPersonal">
        <nav>
          <div class="nav nav-tabs" id="nav-tab" role="tablist">
            <button
              class="nav-link py-md-2 px-md-2 py-1 px-1"
              :class="{ active: orderTemplate.status === 'new' }"
              @click="setStatus('new')"
              id="nav-home-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-home"
              type="button"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              <div class="text-center lh-1">
                <i class="material-icons text-info">sync</i>
                <div class="text-xs text-status-color">Нова</div>
              </div>
            </button>
            <button
              class="nav-link py-md-2 px-md-2 py-1 px-1"
              :class="{ active: orderTemplate.status === 'done' }"
              @click="setStatus('done')"
              id="nav-profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-profile"
              type="button"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
            >
              <div class="text-center lh-1">
                <i class="material-icons status-success-color">check_circle</i>
                <div
                  class="text-xs text-status-color"
                  v-if="company.crmMode !== 'hotel'"
                >
                  Клієнт прийшов
                </div>
                <div
                  class="text-xs text-status-color"
                  v-if="company.crmMode === 'hotel'"
                >
                  Клієнт відвідав
                </div>
              </div>
            </button>
            <button
              class="nav-link py-md-2 px-md-2 py-1 px-1"
              :class="{ active: orderTemplate.status === 'cancelled' }"
              @click="setStatus('cancelled')"
              id="nav-contact-tab"
              data-bs-toggle="tab"
              data-bs-target="#nav-contact"
              type="button"
              role="tab"
              aria-controls="nav-contact"
              aria-selected="false"
            >
              <div class="text-center lh-1">
                <i class="material-icons text-danger">cancel</i>
                <div class="text-xs text-status-color">Відмінено</div>
              </div>
            </button>
          </div>
        </nav>
      </div>

      <div class="tab-content" id="nav-tabContent" v-if="!isPersonal">
        <div
          class="tab-pane fade"
          :class="{
            active: orderTemplate.status === 'new',
            show: orderTemplate.status === 'new',
          }"
          id="nav-home"
          role="tabpanel"
          aria-labelledby="nav-home-tab"
        ></div>
        <div
          class="tab-pane fade"
          :class="{
            active: orderTemplate.status === 'done',
            show: orderTemplate.status === 'done',
          }"
          id="nav-profile"
          role="tabpanel"
          aria-labelledby="nav-profile-tab"
        ></div>
        <div
          class="tab-pane fade"
          :class="{
            active: orderTemplate.status === 'cancelled',
            show: orderTemplate.status === 'cancelled',
          }"
          id="nav-contact"
          role="tabpanel"
          aria-labelledby="nav-contact-tab"
        >
          <div class="row">
            <div class="col-12 text-center text-sm bg-danger text-white">
              Після збереження запис буде скасовано і час буде звільнено для
              нових клієнтів.
            </div>
          </div>
        </div>
      </div>

      <div
        class="row mt-2 mb-2"
        v-if="mode === 'create' && !isPersonal && showGroupSwitcher"
      >
        <div class="col-lg-2 сol-sm-6 text-sm">Груповий запис</div>
        <div class="col-lg-3 сol-sm-6 mb-2">
          <div class="form-check form-switch text-center">
            <input
              class="form-control form-check-input"
              type="checkbox"
              checked
              v-model="groupEnabled"
              @click="toggleGroupEnabledAndRefresh"
            />
          </div>
        </div>
        <div class="col-3"></div>
      </div>

      <div class="row mt-3 mb-2" v-if="mode === 'edit' && !isPersonal">
        <div class="col-lg-6 confirmed">
          <div class="element text-sm" v-if="company.crmMode !== 'hotel'">
            Запис підтверджено
          </div>
          <div class="element text-sm" v-if="company.crmMode === 'hotel'">
            Бронь підтверджено
          </div>
          <div class="form-check form-switch text-center">
            <input
              class="form-control form-check-input"
              type="checkbox"
              checked
              v-model="orderTemplate.isConfirmed"
            />
          </div>
        </div>
        <div
          class="col-lg-6 confirmed alert-warning m-2 p-2 card overbookingAlert"
          v-if="orderTemplate.isOverbooked"
        >
          <span class=""
            ><strong>Overbooking!</strong> Натисніть кнопку, якщо вже обробили
            овербукінг
          </span>
          <button
            class="btn btn-icon bg-primary m-2 customBtn"
            @click="resetOverbookingFlag"
          >
            Так, овербукінг оброблений. Прибрати це повідомлення
          </button>
        </div>
      </div>
      <!-- Modal -->
      <div
        class="modal fade"
        id="unsavedModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="unsavedModalLabel"
        aria-hidden="true"
        ref="unsavedModal"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title font-weight-normal" id="exampleModalLabel">
                Незбережені зміни
              </h5>
              <button
                type="button"
                class="btn-close text-dark"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <p>
                Ви внесли зміни в бронювання, але, здається, забули їх зберегти.
                Бажаєте зберегти?
              </p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-outline-primary"
                data-bs-dismiss="modal"
                @click="unsavedExit"
              >
                Ні, вийти
              </button>
              <button
                type="button"
                class="btn bg-gradient-primary"
                @click="unsavedSave"
              >
                <SmallLoader v-if="isSaveActive" />
                <span v-else>Так, зберегти!</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <!-- Comments for personal order-->
      <div class="card mt-2" id="comments" v-if="isPersonal">
        <div class="card-body">
          <h6>Коментар</h6>
          <div class="col-12">
            <div class="input-group input-group-outline">
              <textarea
                class="form-control"
                style="height: 75px"
                v-model="orderTemplate.comment"
                ref="description"
                rows="3"
                @input="autoResizeTextarea"
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <!-- Comments finish -->

      <div class="row" v-if="mode === 'edit' && !isPersonal">
        <div class="col-lg-5" v-if="dataLoaded">
          <div class="card">
            <div class="card-header p-3 pt-2 bg-transparent">
              <h3 class="mb-3" v-if="company.crmMode !== 'hotel' && isMobile">
                Деталі запису
              </h3>
              <h6
                class="mb-3"
                v-if="company.crmMode !== 'hotel' && isTabletOrDesktop"
              >
                Деталі запису
              </h6>

              <h3 class="mb-3" v-if="company.crmMode === 'hotel' && isMobile">
                Деталі броні
              </h3>
              <h6
                class="mb-3"
                v-if="company.crmMode === 'hotel' && isTabletOrDesktop"
              >
                Деталі броні
              </h6>

              <p class="mb-0 text-sm" v-if="orderTemplate._id">
                <span v-if="company.crmMode === 'hotel'">Номер броні: </span>
                <span v-if="company.crmMode !== 'hotel'">Номер запису: </span>
                <span class="text-sm font-weight-bolder"
                  >{{ orderTemplate.sequenceId || orderTemplate._id }}
                </span>
                <i
                  class="material-icons text-md ms-2 me-2 cursor-pointer"
                  @click="
                    copyToClipboard(
                      orderTemplate.sequenceId?.toString() || orderTemplate._id,
                    )
                  "
                >
                  copy
                </i>
              </p>
              <p class="mb-0 text-sm" v-if="orderTemplate._id">
                <span>ID броні: </span>
                <span class="text-sm font-weight-bolder"
                  >{{ orderTemplate._id }}
                </span>
                <i
                  class="material-icons text-md ms-2 me-2 cursor-pointer"
                  @click="copyToClipboard(orderTemplate._id)"
                >
                  copy
                </i>
              </p>
              <p class="mb-0 text-sm">
                Клієнт:
                <span class="text-sm font-weight-bolder">{{
                  orderDetails.client
                }}</span>
              </p>
              <p class="mb-0 text-sm" v-if="company.crmMode !== 'hotel'">
                Послуга:
                <span class="text text-sm font-weight-bolder">{{
                  orderDetails.services
                }}</span>
                <span v-if="orderDetails.servicesPrefix">{{
                  orderDetails.servicesPrefix
                }}</span>
              </p>
              <p class="mb-0 text-sm" v-if="company.crmMode !== 'hotel'">
                Резерв до:
                <span class="text-sm font-weight-bolder">{{
                  orderDetails.staff
                }}</span>
              </p>
              <p class="mb-0 text-sm" v-if="company.crmMode === 'hotel'">
                Номер:
                <span class="text-sm font-weight-bolder">{{
                  orderDetails.staff
                }}</span>
              </p>
              <p class="mb-0 text-sm" v-if="company.crmMode !== 'hotel'">
                Дата й час:
                <span class="text-sm font-weight-bolder">{{
                  orderDetails.datetime
                }}</span>
              </p>
              <p class="mb-0 text-sm" v-if="company.crmMode === 'hotel'">
                Дати:
                <span class="text-sm font-weight-bolder">{{
                  orderDetails.datetime
                }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-2" v-show="!orderTemplate.externalId">
        <div class="card-body">
          <!-- Клієнт -->
          <div class="row mt-2" v-show="!isPersonal">
            <div class="col-lg-3 mb-3">
              <h3 v-if="isMobile">Клієнт</h3>
              <h6 v-if="isTabletOrDesktop">Клієнт</h6>
            </div>
            <div class="col-lg-9">
              <div class="row mb-3">
                <div class="col-3">
                  <button
                    class="btn btn-icon bg-gradient-secondary"
                    data-bs-toggle="modal"
                    data-bs-target="#modal-add-new-client"
                    id="ref-button-add-new-client"
                    v-show="false"
                  >
                    +
                  </button>
                  <!-- Modal -->
                  <div
                    class="modal fade"
                    id="modal-add-new-client"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                  >
                    <div
                      class="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5
                            class="modal-title font-weight-normal"
                            id="exampleModalLabel"
                          >
                            Новий клієнт
                          </h5>
                          <button
                            type="button"
                            class="btn-close text-dark"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div class="col-lg-12">
                            <div class="row">
                              <div class="col">
                                <div
                                  class="input-group input-group-outline nameInput"
                                  :class="{
                                    'is-filled': newClientModal.firstName,
                                  }"
                                >
                                  <label class="form-label">Імʼя</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="newClientModal.firstName"
                                  />
                                </div>
                              </div>
                              <div class="col">
                                <div
                                  class="input-group input-group-outline lastNameInput"
                                  :class="{
                                    'is-filled': newClientModal.lastName,
                                  }"
                                >
                                  <label class="form-label">Прізвище</label>
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="newClientModal.lastName"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="w-15 input-group has-validation">
                                <select
                                  class="form-control form-select phonecode text-end"
                                  required="required"
                                >
                                  <option
                                    :value="defaultPhoneCode"
                                    selected="selected"
                                    disabled="disabled"
                                  >
                                    +38
                                  </option>
                                </select>
                              </div>
                              <div class="w-60">
                                <div
                                  class="input-group input-group-outline my-3"
                                  :class="{
                                    'is-filled': newClientModal.phoneNumber,
                                    'is-invalid': !isValidPhoneNumber(),
                                  }"
                                >
                                  <label class="form-label"
                                    >Телефон (0671234567)</label
                                  >
                                  <input
                                    @paste="(e) => onPastePhoneNumber(e, true)"
                                    type="tel"
                                    v-mask="'0#########'"
                                    class="form-control"
                                    v-model="newClientModal.phoneNumber"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-lg-6 col-md-6 col-sm-12 mt-2">
                                <div class="row">
                                  <div class="w-100">
                                    <div
                                      class="input-group input-group-outline text-center"
                                      :class="{
                                        'is-filled':
                                          newClientModal.linkInstagram,
                                      }"
                                    >
                                      <label class="form-label"
                                        >Instagram нікнейм</label
                                      >
                                      <input
                                        type="text"
                                        class="form-control text-xs"
                                        v-model="newClientModal.linkInstagram"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="col-lg-6 col-md-6 col-sm-12 mt-2">
                                <div class="row">
                                  <div class="w-100">
                                    <div
                                      class="input-group input-group-outline"
                                      :class="{
                                        'is-filled':
                                          newClientModal.linkTelegram,
                                      }"
                                    >
                                      <label class="form-label"
                                        >Telegram нікнейм</label
                                      >
                                      <input
                                        type="text"
                                        class="form-control text-xs"
                                        v-model="newClientModal.linkTelegram"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="alert alert-danger error-message"
                              v-show="newClientModal.errorMessage"
                            >
                              {{ newClientModal.errorMessage }}
                            </div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn bg-gradient-secondary"
                            id="modal-new-client-close-button"
                            data-bs-dismiss="modal"
                            @click="newClientModalCancel"
                          >
                            закрити
                          </button>
                          <button
                            type="button"
                            class="btn bg-gradient-primary"
                            @click="newClientModalSave"
                            :disabled="!isValidPhoneNumber() || isLoading"
                          >
                            <span v-if="!isLoading">Зберегти</span>
                            <span v-else>
                              <i class="fa fa-spinner fa-spin"></i>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-12">
                  <div class="row">
                    <div class="col-lg-4">
                      <div>
                        <div
                          v-for="(client, index) in orderTemplate.clients"
                          :key="index"
                          v-show="!client._isRemoved"
                        >
                          <div
                            :class="{
                              'disabled-select': openNewClientFields,
                            }"
                            class="d-flex gap-2"
                          >
                            <div class="flex-grow-1">
                              <select
                                :disabled="openNewClientFields"
                                class="form-control flex-grow-1"
                                name="choices-category"
                                :id="`selector-client-${index + 1}`"
                                v-model="client.clientId"
                              ></select>
                            </div>
                            <RouterLink
                              v-if="mode === 'edit'"
                              :disabled="mode !== 'edit'"
                              :to="{
                                name: 'details_client',
                                params: {
                                  id: client?.clientId?._id ?? client?.clientId,
                                },
                              }"
                            >
                              <ButtonIcon :disabled="mode !== 'edit'">
                                <template #icon>
                                  <EyeIcon></EyeIcon>
                                </template>
                              </ButtonIcon>
                            </RouterLink>
                            <div class="w-15" v-show="showRemoveClientButton()">
                              <a
                                class="nav-link text-danger d-flex"
                                data-scroll=""
                                href="#"
                                @click="removeClient(client)"
                              >
                                <i class="material-icons text-sm me-2"
                                  >delete</i
                                >
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2" v-show="groupEnabled">
                    <div class="col">
                      <button
                        class="btn btn-sm btn-outline-dark mb-0"
                        type="button"
                        @click="addClient()"
                        :disabled="isMaxClientsReached()"
                      >
                        + клієнт
                      </button>

                      <span v-show="isMaxClientsReached()">
                        (додано максимум клієнтів для обраної послуги)
                      </span>
                    </div>
                  </div>
                </div>
                <template v-if="!groupEnabled">
                  <div class="form-check custom-form-check">
                    <label class="form-check-label" for="fcustomCheck1">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="fcustomCheck1"
                        v-model="openNewClientFields"
                        style="margin-top: 2px"
                      />
                      Новий клієнт
                    </label>
                  </div>
                  <div
                    class="row d-block d-sm-none"
                    v-if="this.orderTemplate.clientId?.phoneNumber"
                  >
                    <div class="col-lg-4 pe-0">
                      <a
                        :href="`tel:${this.orderTemplate.clientId?.phoneNumber}`"
                      >
                        <button
                          class="btn btn-icon btn-3 btn-outline-primary w-100 phoneCallBtn"
                          type="button"
                        >
                          <span class="btn-inner--icon"
                            ><i class="material-icons">phone</i></span
                          >
                          <span class="btn-inner--text"
                            >Подзвонити
                            {{ this.orderTemplate.clientId?.phoneNumber }}</span
                          >
                        </button>
                      </a>
                    </div>
                  </div>
                  <div v-if="openNewClientFields" class="">
                    <div class="row">
                      <div class="col-lg-4">
                        <label class="custom-form-label">Імʼя</label>
                        <div class="input-group input-group-outline nameInput">
                          <input
                            type="text"
                            class="form-control"
                            v-model="newClientTemplate.firstName"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <label class="custom-form-label">Прізвище</label>
                        <div
                          class="input-group input-group-outline lastNameInput"
                        >
                          <input
                            type="text"
                            class="form-control"
                            v-model="newClientTemplate.lastName"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <div class="row">
                          <label class="custom-form-label"
                            >Телефон (0671234567)</label
                          >
                          <div
                            class="w-20 input-group input-group-outline has-validation"
                          >
                            <select
                              class="form-control form-select phonecode text-center"
                              required="required"
                            >
                              <option
                                :value="defaultPhoneCode"
                                selected="selected"
                                disabled="disabled"
                              >
                                +38
                              </option>
                            </select>
                          </div>
                          <div class="w-80">
                            <div
                              :class="{
                                'is-filled': newClientTemplate.phoneNumber,
                                'is-invalid': !isValidPhoneNumberNewClient(),
                              }"
                              class="input-group input-group-outline"
                            >
                              <input
                                @paste="onPastePhoneNumber"
                                type="tel"
                                v-mask="'0#########'"
                                class="form-control"
                                v-model="newClientTemplate.phoneNumber"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-lg-4">
                        <label class="custom-form-label"
                          >Instagram нікнейм</label
                        >
                        <div
                          class="input-group input-group-outline text-center"
                        >
                          <input
                            type="text"
                            class="form-control text-xs"
                            v-model="newClientTemplate.linkInstagram"
                          />
                        </div>
                      </div>
                      <div class="col-lg-4">
                        <label class="custom-form-label"
                          >Telegram нікнейм</label
                        >
                        <div class="input-group input-group-outline">
                          <input
                            type="text"
                            class="form-control text-xs"
                            v-model="newClientTemplate.linkTelegram"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <div
                  class="alert alert-danger error-message"
                  v-show="newClientModal.errorMessage"
                >
                  {{ newClientModal.errorMessage }}
                </div>
              </div>
            </div>
          </div>

          <!-- Клієнт finish -->

          <!-- Майстер -->
          <div class="row mb-3">
            <div class="col-lg-3 mb-2" v-if="company.crmMode === 'hotel'">
              <h3 v-if="isMobile">Номер</h3>
              <h6 v-if="isTabletOrDesktop">Номер</h6>
            </div>
            <div class="col-lg-3" v-if="company.crmMode !== 'hotel'">
              <h3 v-if="isMobile">{{ getLabelStaff2() }}</h3>
              <h6 v-if="isTabletOrDesktop">{{ getLabelStaff2() }}</h6>
            </div>
            <div class="col-lg-9">
              <div class="col-lg-4">
                <div class="w-lg-85" v-if="dataLoaded">
                  <select
                    class="form-control"
                    name="choices-category"
                    id="selector-staff"
                    v-model="orderTemplate.staffId"
                    @change="onStaffChange"
                  ></select>
                </div>
              </div>
            </div>
          </div>
          <!-- Майстер finish -->

          <!-- Послуга -->
          <div class="row" v-if="!isPersonal && company.crmMode !== 'hotel'">
            <div class="col-lg-3">
              <h3 v-if="isMobile">Послуга</h3>
              <h6 v-if="isTabletOrDesktop">Послуга</h6>
            </div>
            <div class="col-lg-9 mb-3">
              <div
                v-for="(service, index) in orderTemplate.services"
                :key="index"
                v-show="company.crmMode !== 'hotel' && !service._isRemoved"
              >
                <div class="row mb-2">
                  <div class="col-lg-4">
                    <div class="row">
                      <div class="w-85" v-if="dataLoaded">
                        <select
                          class="form-control"
                          name="choices-category"
                          :id="`selector-service-${index + 1}`"
                          v-model="service.serviceId"
                          @change="refreshServicePrices"
                        ></select>
                      </div>
                      <div class="w-15">
                        <a
                          class="nav-link text-danger d-flex"
                          data-scroll=""
                          href="#"
                          @click="removeService(service)"
                          v-if="showRemoveServiceButton()"
                        >
                          <i class="material-icons text-sm me-2">delete</i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div
                  class="row"
                  v-show="company.crmMode !== 'hotel' && !groupEnabled"
                >
                  <div class="col-lg-4">
                    <button
                      class="btn btn-sm btn-outline-dark mb-0"
                      type="button"
                      @click="addService()"
                    >
                      + послуга
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Послуга finish -->

          <!-- кількість гостей -->
          <div
            class="row"
            v-if="
              company.crmMode === 'hotel' &&
              !isPersonal &&
              orderTemplate?.guests?.adults
            "
          >
            <div class="col-lg-3 mt-3">
              <h3 v-if="isMobile">Всього гостей</h3>
              <h6 v-if="isTabletOrDesktop">Всього гостей</h6>
            </div>
            <div class="col-lg-9" v-if="orderTemplate?.guests?.adults">
              <div class="row">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="row">
                      <div class="col-lg-4">
                        <div class="row">
                          <div class="w-50">
                            <label class="custom-form-label">Дорослих</label>
                            <div class="input-group input-group-outline">
                              <select
                                class="form-control text-center"
                                v-model="orderTemplate.guests.adults"
                                @change="onGuestsNumberChange"
                              >
                                <option
                                  v-for="(option, index) in guestsMaxOptions"
                                  :key="index"
                                  :value="option"
                                >
                                  {{ option }}
                                </option>
                              </select>
                            </div>
                          </div>

                          <div class="w-50" v-if="showChildrenInput">
                            <label class="custom-form-label"
                              >Дітей безкоштовно</label
                            >
                            <div class="input-group input-group-outline">
                              <select
                                class="form-control text-center"
                                v-model="orderTemplate.guests.children"
                              >
                                <option :value="0">0</option>
                                <option
                                  v-for="(option, index) in guestsMaxOptions"
                                  :key="index"
                                  :value="option"
                                >
                                  {{ option }}
                                </option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr
            v-if="
              company.crmMode === 'hotel' &&
              !isPersonal &&
              showDevFeatures &&
              orderTemplate?.guests?.adults
            "
          />
          <!-- кількість гостей finish -->

          <!-- Підсумок -->
          <div class="row mt-3">
            <div class="col-lg-3 mt-3">
              <h3 v-if="company.crmMode === 'hotel' && isMobile">
                Дата заїзду і виїзду
              </h3>
              <h6 v-if="company.crmMode === 'hotel' && isTabletOrDesktop">
                Дата заїзду і виїзду
              </h6>

              <h3 v-if="company.crmMode !== 'hotel' && isMobile">Дата й час</h3>
              <h6 v-if="company.crmMode !== 'hotel' && isTabletOrDesktop">
                Дата й час
              </h6>
            </div>
            <div class="col-lg-9">
              <div class="row">
                <div
                  class="d-flex flex-column flex-lg-row gap-3 gap-lg-5 align-items-lg-start"
                >
                  <div class="max-width-content">
                    <span v-if="showDuration()">{{ displayDayOfWeek() }}</span>
                    <div v-if="company.crmMode === 'hotel'">
                      <div
                        class="d-flex align-items-center justify-content-between gap-2 max-width"
                      >
                        <h6 class="mb-0">
                          <span v-if="!serviceDateTo && serviceDateFrom"
                            >Оберіть дату виїзду
                          </span>
                          <span v-else-if="serviceDateTo"
                            >Обрано діб: {{ serviceDays }}</span
                          >
                          <span v-else>Оберіть дату заїзду</span>
                        </h6>
                        <button
                          :class="{ disabled: !serviceDateFrom }"
                          class="btn btn-link text-dark text-decoration-underline mb-0 p-0"
                          @click="resetDate"
                        >
                          Скинути дати
                        </button>
                      </div>
                    </div>
                    <p class="description mb-0">
                      <span> {{ ISODateToDay(serviceDateFrom) }} - </span>
                      {{ ISODateToDay(serviceDateTo) }}
                    </p>

                    <div
                      class="input-group input-group-outline mt-lg-2"
                      :class="{ 'is-filled': orderTemplate.serviceDate }"
                    >
                      <flat-pickr
                        v-model="orderTemplate.serviceDate"
                        :config="calendarConfig"
                        ref="datePickerWrap"
                        class="form-control datetimepicker text-xs bmn-calendar-input"
                      >
                      </flat-pickr>
                    </div>
                  </div>
                  <div class="">
                    <div class="row" v-if="showDuration()">
                      <div class="col-6">
                        <label class="mt-2"><b>Початок</b></label>
                      </div>
                      <div class="col-6">
                        <label class="mt-2"><b>Кінець</b></label>
                      </div>
                      <div class="w-25" v-show="showDurationField">
                        <div
                          class="input-group input-group-outline mb-2"
                          :class="{ 'is-filled': startTimeAsString.hours }"
                        >
                          <label class="form-label">Год.</label>
                          <input
                            type="tel"
                            class="form-control"
                            min="0"
                            max="23"
                            step="1"
                            @change="onStartTimeChange"
                            v-model="startTimeAsString.hours"
                          />
                        </div>
                      </div>
                      <div class="w-25" v-show="showDurationField">
                        <div
                          class="input-group input-group-outline mb-2"
                          :class="{ 'is-filled': startTimeAsString.minutes }"
                        >
                          <label class="form-label">Хв.</label>
                          <input
                            type="tel"
                            class="form-control"
                            min="0"
                            max="59"
                            step="10"
                            @change="onStartTimeChange"
                            v-model="startTimeAsString.minutes"
                          />
                        </div>
                      </div>
                      <div class="w-25" v-show="showDurationField">
                        <div
                          class="input-group input-group-outline mb-2"
                          :class="{ 'is-filled': endTimeAsString.hours }"
                        >
                          <label class="form-label">Год.</label>
                          <input
                            type="tel"
                            class="form-control"
                            min="0"
                            max="23"
                            step="1"
                            v-model="endTimeAsString.hours"
                          />
                        </div>
                      </div>
                      <div class="w-25" v-show="showDurationField">
                        <div
                          class="input-group input-group-outline mb-2"
                          :class="{ 'is-filled': endTimeAsString.minutes }"
                        >
                          <label class="form-label">Хв.</label>
                          <input
                            type="tel"
                            class="form-control"
                            min="0"
                            max="59"
                            step="10"
                            v-model="endTimeAsString.minutes"
                          />
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="!isPersonal">
                      <div class="lg-w-25">
                        <span
                          v-if="company.crmMode === 'hotel'"
                          class="custom-form-label"
                          >Проживання:</span
                        >
                        <span
                          v-if="company.crmMode === 'general'"
                          class="custom-form-label"
                          >Вартість послуг:</span
                        >

                        <span
                          >&nbsp;
                          {{ getLabelServicesCost() }}
                          грн</span
                        >
                      </div>
                    </div>
                    <div
                      class="row"
                      v-show="
                        !isPersonal &&
                        mode !== 'create' &&
                        priceBreakdown.fixedServicesCost !==
                          priceBreakdown.servicesCost
                      "
                    >
                      <div class="lg-w-25">
                        <span
                          class="custom-form-label text-xs"
                          v-if="company.crmMode === 'hotel'"
                          >Ціни за перебування на обраний період змінились і
                          нова вартість може складати:</span
                        >
                        <span
                          class="custom-form-label text-xs"
                          v-if="company.crmMode !== 'hotel'"
                          >Перелік послуг в записі змінився. Нова вартість може
                          складати:</span
                        >

                        <span class="text-xs"
                          >&nbsp; {{ priceBreakdown.servicesCost }} грн</span
                        >

                        <div>
                          <button
                            type="button"
                            class="btn btn-sm btn-outline-primary"
                            @click="setFixedFinalPrice"
                          >
                            Застосувати
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Підсумок finish-->
          <hr v-if="!isPersonal && additionalServices?.length" />
          <!-- Додаткові послуги-->
          <div class="row" v-if="!isPersonal && additionalServices?.length">
            <div class="col-lg-3">
              <h3 v-if="isMobile">Додаткові послуги</h3>
              <h6 v-if="isTabletOrDesktop">Додаткові послуги</h6>
            </div>
            <div class="col-lg-9">
              <div class="row">
                <div class="table-responsive">
                  <table class="table table-hover" id="datatable-search">
                    <thead class="thead-light">
                      <tr>
                        <th
                          class="text-uppercase text-xxs font-weight-bolder tablepayment text-start"
                        >
                          Обрати
                        </th>
                        <th
                          class="text-uppercase text-xxs font-weight-bolder tablepayment text-center"
                        >
                          Назва
                        </th>
                        <th
                          class="text-uppercase text-xxs font-weight-bolder tablepayment text-center"
                        >
                          Кількість
                        </th>
                        <th
                          class="text-uppercase text-xxs font-weight-bolder tablepayment text-center"
                        >
                          Вартість (грн)
                        </th>
                        <th
                          class="text-uppercase text-xxs font-weight-bolder tablepayment text-center"
                        >
                          Передплата (грн)
                        </th>
                        <th
                          class="text-uppercase text-xxs font-weight-bolder tablepayment text-center"
                          v-if="company.crmMode === 'hotel'"
                        >
                          Ціна Подобова
                        </th>
                        <th
                          class="text-uppercase text-xxs font-weight-bolder tablepayment text-center"
                          v-if="company.crmMode === 'hotel'"
                        >
                          Ціна За Гостя
                        </th>
                        <th
                          class="text-uppercase text-xxs font-weight-bolder tablepayment text-center"
                        >
                          Обрана за замовчуванням
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(additionalService, index) in additionalServices"
                        :key="index"
                      >
                        <td class="text-start text-xs">
                          <div class="form-check freepayment">
                            <input
                              class="form-check-input"
                              type="checkbox"
                              v-model="additionalService.isSelected"
                              @change="
                                onAdditionalServiceCheck(additionalService)
                              "
                            />
                          </div>
                        </td>
                        <td class="text-center text-xs">
                          <span>{{ additionalService.name }}</span>
                        </td>
                        <td class="text-start text-xs">
                          <div class="input-group input-group-outline">
                            <select
                              class="form-control text-center"
                              v-model="additionalService.quantity"
                              @change="
                                onAdditionalServiceQuantityChange(
                                  additionalService,
                                )
                              "
                            >
                              <option
                                v-for="(
                                  option, index
                                ) in additionalServicesQuantityOptions"
                                :key="index"
                                :value="option"
                              >
                                {{ option }}
                              </option>
                            </select>
                          </div>
                        </td>
                        <td class="text-center text-xs">
                          <span>{{ additionalService.price }} грн</span>
                          &nbsp;
                          <span v-if="additionalService.isPricePerDay"
                            >(подобово)</span
                          >
                        </td>
                        <td class="text-center text-xs">
                          <span
                            >{{ additionalService.prepaymentAmount }} грн</span
                          >
                        </td>
                        <td
                          class="text-center text-xs"
                          v-if="company.crmMode === 'hotel'"
                        >
                          <span>{{
                            additionalService.isPricePerDay ? "Так" : "Ні"
                          }}</span>
                        </td>
                        <td
                          class="text-center text-xs"
                          v-if="company.crmMode === 'hotel'"
                        >
                          <span>{{
                            additionalService.isPricePerGuest ? "Так" : "Ні"
                          }}</span>
                        </td>
                        <td class="text-center text-xs">
                          <span>{{
                            additionalService.isPreselected ? "Так" : "Ні"
                          }}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <!-- Додаткові послуги finish-->
        </div>
      </div>

      <!-- Вартість -->
      <div
        class="card mt-2"
        id="order"
        v-if="!isPersonal && !orderTemplate.externalId"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3 mt-2">
              <h3 v-if="company.crmMode === 'hotel' && isMobile">
                Вартість бронювання
              </h3>
              <h6 v-if="company.crmMode === 'hotel' && isTabletOrDesktop">
                Вартість бронювання
              </h6>
              <h3 v-if="company.crmMode !== 'hotel' && isMobile">
                Вартість запису
              </h3>
              <h6 v-if="company.crmMode !== 'hotel' && isTabletOrDesktop">
                Вартість запису
              </h6>
            </div>
            <div class="col-lg-9 mt-2">
              <div class="row">
                <div class="col-lg-6 span text-sm">
                  <div class="row">
                    <div class="col-8">
                      <text class="text-bold">Загальна вартість:</text>
                    </div>
                    <div class="col-4 text-end">
                      <text class="text-bold"
                        >{{ getLabelTotalPrice() }} грн</text
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <span class="">Знижка:</span>
                    </div>
                    <div class="col-4 text-end">
                      <text class="">{{ getLabelDiscountAmount() }} грн</text>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-8">
                      <span class="text">Сплачено: </span>
                    </div>
                    <div class="col-4 text-end">
                      <text class="text text-success"
                        >{{ priceBreakdown.paidAmount }}
                        грн
                      </text>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-8">
                      <span class="text">Повернення: </span>
                    </div>
                    <div class="col-4 text-end">
                      <text class="text"
                        >{{ priceBreakdown.refundedAmount }}
                        грн
                      </text>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-8">
                      <span class="text">До сплати: </span>
                    </div>
                    <div class="col-4 text-end">
                      <text class="">{{ getLabelUnpaidAmount() }} грн</text>
                    </div>
                  </div>

                  <hr />
                  <text class="text-bold">Деталізація</text>
                  <div class="row">
                    <div class="col-8">
                      <span class="" v-if="company.crmMode === 'hotel'"
                        >Проживання:
                      </span>
                      <span class="" v-if="company.crmMode === 'general'"
                        >Послуги:
                      </span>
                    </div>
                    <div class="col-4 text-end">
                      <text class=""
                        >{{ priceBreakdown.fixedServicesCost }} грн</text
                      >
                      <span class="">
                        (передплата
                        {{ priceBreakdown.fixedServicesPrepaymentAmount }}
                        грн)</span
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <span class="">Додаткові послуги: </span>
                    </div>
                    <div class="col-4 text-end">
                      <text class=""
                        >{{ getLabelAdditionalServicesCost() }} грн</text
                      >
                      <span class="">
                        (передплата
                        {{ getLabelAdditionalServicesPrepaymentAmount() }}
                        грн)</span
                      >
                    </div>
                  </div>
                  <div
                    v-for="(item, idx) of orderTemplate.partnerTotalPrices"
                    v-bind:key="idx"
                  >
                    <div class="row" v-if="item.id.isActive">
                      <div class="col-8">
                        <span class="">{{ item.id.name }}: </span>
                      </div>
                      <div class="col-4 text-end">
                        <text class="">{{ item.totalPrice }} грн</text>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <span class="">Всього передплата: </span>
                    </div>
                    <div class="col-4 text-end">
                      <text class=""
                        >{{
                          priceBreakdown.fixedServicesPrepaymentAmount +
                          priceBreakdown.additionalServicesPrepaymentAmount -
                          priceBreakdown.discountAmountForPrepayment
                        }}
                        грн</text
                      >
                    </div>
                  </div>
                  <div class="row" v-if="orderTemplate.prepaymentExpiresAt">
                    <div>
                      <span class="">Очікуємо передоплату до: </span>
                      <text class="text-danger text-bold">
                        {{ showDateTime(orderTemplate.prepaymentExpiresAt) }}
                      </text>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Вартість finish-->

      <!-- Знижки -->
      <div class="card mt-2" v-if="!isPersonal && !orderTemplate.externalId">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3">
              <h3 v-if="isMobile">Знижки</h3>
              <h6 v-if="isTabletOrDesktop">Знижки</h6>
            </div>
            <div class="col-lg-9">
              <div class="row">
                <div class="col-lg-6">
                  <div class="row">
                    <div class="col-8">
                      <span class="">Знижка:</span>
                    </div>
                    <div class="col-4 text-end">
                      <span class="">{{ getLabelDiscountAmount() }} грн</span>
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
              <div class="row">
                <div class="col-lg-3 text-sm">
                  <label class="custom-form-label">Додати знижку</label>

                  <div class="input-group input-group-outline paymentInput">
                    <input
                      type="tel"
                      placeholder="Введіть суму в грн"
                      class="form-control"
                      :style="{ fontSize: '14px !important' }"
                      v-model="doDiscountAmount"
                    />
                  </div>
                </div>
                <div class="col-lg-9">
                  <div class="row col-sm-auto col-12">
                    <label class="custom-form-label">Обрати тип знижки</label>
                    <div class="col col-md-auto">
                      <button
                        class="btn btn-icon btn-3 btn-outline-primary"
                        :class="{
                          'disabled-decrease-opacity':
                            !showDiscount('absolute'),
                        }"
                        :disabled="
                          isInvalidDiscountAmount || !showDiscount('absolute')
                        "
                        style="
                          width: 110px;
                          padding-left: 5px;
                          padding-right: 5px;
                        "
                        type="button"
                        @click="doDiscount('absolute')"
                      >
                        <SmallLoader
                          :type="'dark'"
                          v-if="isLoadingDiscount"
                        ></SmallLoader>
                        <template v-else>
                          <span class="btn-inner--icon">₴</span>
                          <span class="btn-inner--text"> гривні</span>
                        </template>
                      </button>
                    </div>
                    <div class="col col-md-auto">
                      <button
                        class="btn btn-outline-primary"
                        :class="{
                          'disabled-decrease-opacity':
                            !showDiscount('percentage'),
                        }"
                        style="
                          width: 110px;
                          padding-left: 5px;
                          padding-right: 5px;
                        "
                        type="button"
                        :disabled="
                          isInvalidDiscountAmount ||
                          doDiscountAmount <= 0 ||
                          doDiscountAmount > 100 ||
                          !showDiscount('percentage')
                        "
                        @click="doDiscount('percentage')"
                      >
                        <SmallLoader
                          :type="'dark'"
                          v-if="isLoadingDiscount"
                        ></SmallLoader>
                        <template v-else>
                          <span class="btn-inner--icon"
                            ><i class="material-icons">percent</i></span
                          >
                          <span class="btn-inner--text">відсоток</span>
                        </template>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Знижки finish-->

      <!-- Оплата -->
      <div class="card mt-2" v-if="!isPersonal && !orderTemplate.externalId">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3 mt-2">
              <h3 v-if="company.crmMode === 'hotel' && isMobile">
                Оплата бронювання
              </h3>
              <h3 v-if="company.crmMode !== 'hotel' && isMobile">
                Оплата запису
              </h3>

              <h6 v-if="company.crmMode === 'hotel' && isTabletOrDesktop">
                Оплата бронювання
              </h6>
              <h6 v-if="company.crmMode !== 'hotel' && isTabletOrDesktop">
                Оплата запису
              </h6>
            </div>
            <div class="col-lg-9">
              <div class="row">
                <div class="col-lg-6">
                  <div class="row">
                    <div class="col-8">
                      <span class="">До сплати:</span>
                    </div>
                    <div class="col-4 text-end">
                      <span class=""
                        >{{ getMainServiceRemainingAmount() }} грн</span
                      >
                    </div>
                  </div>
                  <hr />
                </div>
              </div>

              <!-- this is for abonements
                <div v-if="showDevFeatures && company.crmMode !== 'hotel'">
                <label for="exampleFormControlSelect1" class="ms-0"
                  >Оберіть клієнта</label
                >
                <select
                  class="form-control text-xs"
                  id="exampleFormControlSelect1"
                >
                  <option>Майкл Джордан</option>
                  <option>Артем Павлов</option>
                  <option>Сергій Петров</option>
                </select>
              </div> -->
              <div class="row">
                <div class="col-12 col-lg-3 span text-sm">
                  <label class="custom-form-label">Додати оплату</label>
                  <div class="input-group input-group-outline paymentInput">
                    <input
                      type="tel"
                      class="form-control"
                      :style="{ fontSize: '14px !important' }"
                      placeholder="Введіть суму в грн"
                      v-model="doPaymentAmount"
                    />
                  </div>
                </div>
                <div class="col-lg-9">
                  <div class="row col-sm-auto col-12">
                    <label class="custom-form-label"
                      >Обрати спосіб оплати</label
                    >
                  </div>
                  <div class="row col-sm-auto col-12 row-cols-sm-auto p-0 m-0">
                    <div class="col col-md-auto">
                      <button
                        class="btn btn-icon btn-3 btn-outline-primary"
                        :disabled="isLoadingPayment || isInvalidPaymentAmount"
                        style="
                          width: 110px;
                          padding-left: 5px;
                          padding-right: 5px;
                        "
                        type="button"
                        @click="doPayment('cash')"
                      >
                        <SmallLoader
                          :type="'dark'"
                          v-if="isLoadingPayment && currentPayment === 'cash'"
                        ></SmallLoader>
                        <template v-else>
                          <span class="btn-inner--icon"
                            ><i class="material-icons">payments</i></span
                          >
                          <span class="btn-inner--text"> Готівка</span>
                        </template>
                      </button>
                    </div>
                    <div class="col col-md-auto">
                      <button
                        class="btn btn-outline-primary"
                        style="
                          width: 110px;
                          padding-left: 5px;
                          padding-right: 5px;
                        "
                        type="button"
                        :disabled="isLoadingPayment || isInvalidPaymentAmount"
                        @click="doPayment('card-transfer')"
                      >
                        <SmallLoader
                          :type="'dark'"
                          v-if="
                            isLoadingPayment &&
                            currentPayment === 'card-transfer'
                          "
                        ></SmallLoader>
                        <template v-else>
                          <span class="btn-inner--icon"
                            ><i class="material-icons">credit_card</i></span
                          >
                          <span class="btn-inner--text"> На картку</span>
                        </template>
                      </button>
                    </div>
                    <div
                      class="col col-md-auto"
                      v-if="branch.paymentMonobankEnabled"
                    >
                      <button
                        class="btn btn-icon btn-3 btn-outline-primary"
                        :disabled="isLoadingPayment || isInvalidPaymentAmount"
                        style="
                          width: 110px;
                          padding-left: 5px;
                          padding-right: 5px;
                        "
                        type="button"
                        @click="doPayment('monobank-acquiring')"
                      >
                        <SmallLoader
                          :type="'dark'"
                          v-if="
                            isLoadingPayment &&
                            currentPayment === 'monobank-acquiring'
                          "
                        ></SmallLoader>
                        <template v-else>
                          <span class="btn-inner--icon"></span>
                          <span class="btn-inner--text"> plata by mono</span>
                        </template>
                      </button>
                    </div>
                    <div class="col-lg-2" v-if="branch.paymentLiqPayEnabled">
                      <button
                        class="btn btn-icon btn-3 btn-outline-primary"
                        :disabled="isLoadingPayment || isInvalidPaymentAmount"
                        style="
                          width: 110px;
                          padding-left: 5px;
                          padding-right: 5px;
                        "
                        type="button"
                        @click="doPayment('liqpay-acquiring')"
                      >
                        <SmallLoader
                          :type="'dark'"
                          v-if="
                            isLoadingPayment &&
                            currentPayment === 'liqpay-acquiring'
                          "
                        ></SmallLoader>
                        <template v-else>
                          <span class="btn-inner--icon"
                            ><i class="material-icons">receipt_long</i></span
                          >
                          <span class="btn-inner--text">LiqPay</span>
                        </template>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- todo: придумати куди перенести шаблон -->
          <!-- <div class="row" v-if="showDevFeatures">
            <div class="col-3 mt-2">
              <h6>Шаблон для інформування клієнтів</h6>
            </div>
            <div class="col-lg-9 mt-3">
              <div class="row" v-if="showDevFeatures">
                <div class="col-lg-9">
                  <div class="row">
                    <div class="w-90">
                      <div class="input-group input-group-outline">
                        <input
                          type="text"
                          class="form-control"
                          value="Вітаємо! Дякуємо за бронювання. Щоб підтвердити бронювання, відправляємо вам посилання на оплату: 3123124281475128"
                        />
                      </div>
                    </div>
                    <div class="w-10">
                      <div class="col-md-2 mt-2">
                        <i class="material-icons text-lg me-2 cursor-pointer"
                          >copy</i
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-3 span text-sm">
                  <div class="d-flex justify-content-center mb-1">
                    <div class="col-12">
                      <button class="btn btn-success px-3 mb-0" type="button">
                        Зберегти
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr /> -->
          <div
            class="row"
            v-if="
              orderTemplate.discounts?.length || orderTemplate.payments?.length
            "
          >
            <label>Всі транзакції</label>
            <div class="table-responsive">
              <table class="table table-hover" id="datatable-search2">
                <thead class="thead-light">
                  <tr>
                    <th
                      class="text-uppercase text-xxs font-weight-bolder tablepayment"
                    >
                      Дата і час
                    </th>
                    <th
                      class="text-uppercase text-xxs font-weight-bolder tablepayment"
                    >
                      Транзакція
                    </th>
                    <th
                      class="text-uppercase text-xxs font-weight-bolder tablepayment"
                    >
                      Автор
                    </th>
                    <th
                      class="text-uppercase text-xxs font-weight-bolder tablepayment"
                    >
                      Сума
                    </th>
                    <th
                      class="text-uppercase text-xxs font-weight-bolder tablepayment"
                    >
                      Статус
                    </th>
                    <th
                      class="text-uppercase text-xxs font-weight-bolder tablepayment"
                    >
                      Посилання
                    </th>
                    <th
                      class="text-uppercase text-xxs font-weight-bolder tablepayment"
                    >
                      Дія
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="discount in orderTemplate.discounts"
                    :key="discount._id"
                  >
                    <td class="text-xs font-weight-normal tablepayment">
                      <span>{{ showDateTime(discount.createdAt) }}</span>
                    </td>
                    <td class="text-xs font-weight-normal tablepayment">
                      <span>Знижка</span>
                    </td>
                    <td class="text-xs font-weight-normal tablepayment">
                      <span v-if="discount.createdSource === 'clientWebForm'"
                        >Клієнт</span
                      >
                      <span v-else
                        >{{ discount.createdBy?.firstName }}
                        {{ discount.createdBy?.lastName }}</span
                      >
                    </td>
                    <td class="text-xs font-weight-normal tablepayment">
                      <span>{{ discount.amount }}</span>
                      <span v-if="discount.type === 'absolute'"> грн</span>
                      <span v-if="discount.type === 'percentage'"> %</span>
                    </td>
                    <td class="text-xs font-weight-normal"></td>
                    <td class="text-xs font-weight-normal"></td>
                    <td class="text-xs font-weight-normal tablepayment">
                      <div class="btn-group gap-3">
                        <div
                          class="d-inline-flex align-items-center justify-content-center"
                          v-if="isAllowedDeleteDiscount(discount)"
                        >
                          <i
                            class="material-icons text-lg me-2 cursor-pointer"
                            data-bs-toggle="modal"
                            style="color: red"
                            :data-bs-target="`#${modalParamsRemoveDiscountTransaction.modalId}`"
                            @click="
                              removeDiscountTransactionPreHook(discount._id)
                            "
                          >
                            delete
                          </i>
                        </div>
                      </div>
                    </td>
                  </tr>

                  <tr
                    v-for="(payment, indexPayment) in orderTemplate.payments"
                    :key="indexPayment"
                    :class="{
                      'slightly-red-bg': payment.operationType === 'refund',
                    }"
                  >
                    <td class="text-xs font-weight-normal tablepayment">
                      <span>{{ showDateTime(payment.createdAt) }}</span>
                    </td>
                    <td class="text-xs font-weight-normal tablepayment">
                      <span v-if="payment.operationType === 'cash'"
                        >Оплата готівка</span
                      >
                      <span
                        v-else-if="payment.operationType === 'card-transfer'"
                        >Оплата картка</span
                      >
                      <span
                        v-else-if="
                          payment.operationType === 'monobank-acquiring'
                        "
                        >Оплата онлайн</span
                      >
                      <span
                        v-else-if="payment.operationType === 'liqpay-acquiring'"
                        >Оплата онлайн</span
                      >
                      <span v-else-if="payment.operationType === 'refund'"
                        >Повернення оплати</span
                      >
                    </td>
                    <td class="text-xs font-weight-normal tablepayment">
                      <span v-if="payment.createdSource === 'clientWebForm'"
                        >Клієнт</span
                      >
                      <span v-else
                        >{{ payment.createdBy?.firstName }}
                        {{ payment.createdBy?.lastName }}</span
                      >
                    </td>
                    <td class="text-xs font-weight-normal tablepayment">
                      <span>{{ payment.amount }} грн</span>
                    </td>
                    <td class="text-xs font-weight-normal">
                      <span
                        class="badge badge-dot"
                        v-if="payment.status === 'pending'"
                      >
                        <i class="bg-info"></i>
                        <span class="text-dark text-xs">Очікує</span>
                      </span>
                      <span
                        class="badge badge-dot"
                        v-else-if="payment.status === 'success'"
                      >
                        <i class="bg-success"></i>
                        <span
                          class="text-dark text-xs"
                          v-if="payment.operationType === 'refund'"
                          >Повернуто</span
                        >
                        <span class="text-dark text-xs" v-else>Сплачено</span>
                        <span class="text-dark text-xs" v-if="payment.paidAt">
                          <br />
                          {{ showDateTime(payment.paidAt) }}
                        </span>
                      </span>
                      <span
                        class="badge badge-dot"
                        v-else-if="payment.status === 'reversed'"
                      >
                        <i class="bg-success"></i>
                        <span class="text-dark text-xs"
                          >Сплачено (+ refund)</span
                        >
                        <span class="text-dark text-xs" v-if="payment.paidAt">
                          <br />
                          {{ showDateTime(payment.paidAt) }}
                        </span>
                      </span>
                      <span
                        class="badge badge-dot"
                        v-else-if="payment.status === 'failure'"
                      >
                        <i class="bg-warning"></i>
                        <span class="text-dark text-xs">Помилка</span>
                      </span>
                      <span
                        class="badge badge-dot"
                        v-else-if="payment.status === 'cancelled'"
                      >
                        <i class="bg-warning"></i>
                        <span class="text-dark text-xs">Відмінено</span>
                      </span>
                      <span
                        class="badge badge-dot"
                        v-else-if="payment.status === 'expired'"
                      >
                        <i class="bg-warning"></i>
                        <span class="text-dark text-xs">Прострочено</span>
                      </span>
                    </td>
                    <td class="text-xs font-weight-normal tablepayment">
                      <div v-if="payment.monobankInvoice?.invoiceId">
                        <span>
                          <a :href="payment.monobankInvoice?.pageUrl">
                            {{ payment.monobankInvoice?.pageUrl }}
                          </a>
                        </span>
                        <i
                          class="material-icons text-md ms-2 me-2 cursor-pointer"
                          @click="
                            copyToClipboard(payment.monobankInvoice?.pageUrl)
                          "
                        >
                          copy
                        </i>
                      </div>
                      <div v-if="payment.liqPayInvoice?.invoiceId">
                        <span>
                          <a :href="payment.liqPayInvoice?.pageUrl">
                            {{ payment.liqPayInvoice?.pageUrl }}
                          </a>
                        </span>
                        <i
                          class="material-icons text-md ms-2 me-2 cursor-pointer"
                          @click="
                            copyToClipboard(payment.liqPayInvoice?.pageUrl)
                          "
                        >
                          copy
                        </i>
                      </div>
                    </td>
                    <td class="text-xs font-weight-normal tablepayment">
                      <div class="btn-group gap-3">
                        <span
                          v-if="
                            payment.monobankInvoice?.invoiceId &&
                            !['success', 'reversed'].includes(payment.status)
                          "
                          @click="
                            selectedQrUrl = payment.monobankInvoice?.pageUrl
                          "
                          data-bs-toggle="modal"
                          :data-bs-target="`#${modalParamsQrCode.modalId}`"
                          class="d-inline-flex cursor-pointer"
                        >
                          <i class="material-icons" style="color: black">
                            qr_code_scanner</i
                          >
                        </span>

                        <span
                          v-if="
                            payment.liqPayInvoice?.invoiceId &&
                            !['success', 'reversed'].includes(payment.status)
                          "
                          @click="
                            selectedQrUrl = payment.liqPayInvoice?.pageUrl
                          "
                          data-bs-toggle="modal"
                          :data-bs-target="`#${modalParamsQrCode.modalId}`"
                          class="d-inline-flex cursor-pointer"
                        >
                          <i class="material-icons"> qr_code_scanner</i>
                        </span>

                        <div
                          class="d-inline-flex align-items-center ms-2 justify-content-center"
                          data-bs-toggle="modal"
                          :data-bs-target="`#${modalParamsInvoiceSms.modalId}`"
                          v-if="isAllowedToInvoiceSms(payment)"
                          @click="
                            showInvoiceModal(
                              this.orderTemplate._id,
                              this.orderTemplate.clientId?.phoneNumber,
                              payment,
                            )
                          "
                        >
                          <i
                            class="material-icons text-lg me-2 cursor-pointer"
                            style="color: #4ea0dc"
                          >
                            send
                          </i>
                        </div>
                        <div
                          class="d-inline-flex align-items-center justify-content-center"
                          v-if="isAllowedDeletePayment(payment)"
                        >
                          <i
                            class="material-icons text-lg me-2 cursor-pointer"
                            data-bs-toggle="modal"
                            :data-bs-target="`#${modalParamsRemovePaymentTransaction.modalId}`"
                            style="color: red"
                            @click="
                              removePaymentTransactionPreHook(payment._id)
                            "
                          >
                            delete
                          </i>
                        </div>
                        <div
                          class="d-inline-flex align-items-center justify-content-center"
                          v-if="isAllowedRefundPayment(payment)"
                        >
                          <i
                            class="material-icons text-lg me-2 cursor-pointer"
                            style="color: orange"
                            data-bs-toggle="modal"
                            :data-bs-target="`#${modalParamsRefundPaymentTransaction.modalId}`"
                            @click="
                              refundPaymentTransactionPreHook(
                                payment._id,
                                null,
                                payment.amount,
                              )
                            "
                          >
                            keyboard_return
                          </i>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <!-- Оплата finish-->

      <!-- Оплата чека POSter -->
      <div
        class="card mt-2"
        v-if="!isPersonal && isPosterEnabled() && !orderTemplate.externalId"
      >
        <div class="card-body">
          <div
            class="row"
            v-for="(item, idx) of orderTemplate.partnerTotalPrices"
            v-bind:key="idx"
          >
            <div class="col-lg-3 mt-2">
              <h3 v-if="isMobile">{{ item.id.name }} чеки</h3>
              <h6 v-if="isTabletOrDesktop">{{ item.id.name }}</h6>
            </div>
            <div class="col-lg-9">
              <div class="row">
                <div class="col-lg-6">
                  <div class="row">
                    <div class="col-8">
                      <span class="">Загальна вартість:</span>
                    </div>
                    <div class="col-4 text-end">
                      <span class="">{{ item.totalPrice }} грн</span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <span class="text-sm">Сплачено:</span>
                    </div>
                    <div class="col-4 text-end">
                      <span class="text-sm">{{ item.paidAmount }} грн</span>
                      <!-- TODO: Fix! -->
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <span class="text-sm">Залишок:</span>
                    </div>
                    <div class="col-4 text-end">
                      <span class="text-sm"
                        >{{ item.totalPrice - item.paidAmount }} грн</span
                      >
                      <!-- TODO: Fix! -->
                    </div>
                  </div>
                  <hr />
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-3 span text-sm">
                  <label class="custom-form-label">Додати оплату</label>
                  <div class="input-group input-group-outline paymentInput">
                    <input
                      type="tel"
                      class="form-control"
                      :style="{ fontSize: '14px !important' }"
                      placeholder="Введіть суму в грн"
                      v-model="partnerPaymentAmount[item.id._id]"
                    />
                  </div>
                </div>

                <div class="col-lg-9">
                  <div class="row col-sm-auto col-12">
                    <label class="custom-form-label"
                      >Обрати спосіб оплати</label
                    >
                  </div>
                  <div class="row col-sm-auto col-12 row-cols-sm-auto p-0 m-0">
                    <div class="col col-md-auto">
                      <button
                        class="btn btn-icon btn-3 btn-outline-primary"
                        :disabled="
                          isLoadingPayment ||
                          isInvalidPartnerPaymentAmount(item.id._id)
                        "
                        style="
                          width: 110px;
                          padding-left: 5px;
                          padding-right: 5px;
                        "
                        type="button"
                        @click="doPayment('cash', item.id._id)"
                      >
                        <SmallLoader
                          :type="'dark'"
                          v-if="isLoadingPayment && currentPayment === 'cash'"
                        ></SmallLoader>
                        <template v-else>
                          <span class="btn-inner--icon"
                            ><i class="material-icons">payments</i></span
                          >
                          <span class="btn-inner--text"> Готівка</span>
                        </template>
                      </button>
                    </div>
                    <div class="col col-md-auto">
                      <button
                        class="btn btn-outline-primary"
                        style="
                          width: 110px;
                          padding-left: 5px;
                          padding-right: 5px;
                        "
                        type="button"
                        :disabled="
                          isLoadingPayment ||
                          isInvalidPartnerPaymentAmount(item.id._id)
                        "
                        @click="doPayment('card-transfer', item.id._id)"
                      >
                        <SmallLoader
                          :type="'dark'"
                          v-if="
                            isLoadingPayment &&
                            currentPayment === 'card-transfer'
                          "
                        ></SmallLoader>
                        <template v-else>
                          <span class="btn-inner--icon"
                            ><i class="material-icons">credit_card</i></span
                          >
                          <span class="btn-inner--text"> На картку</span>
                        </template>
                      </button>
                    </div>
                    <div
                      class="col col-md-auto"
                      v-if="branch.paymentMonobankEnabled"
                    >
                      <button
                        class="btn btn-icon btn-3 btn-outline-primary"
                        :disabled="
                          isLoadingPayment ||
                          isInvalidPartnerPaymentAmount(item.id._id)
                        "
                        style="
                          width: 110px;
                          padding-left: 5px;
                          padding-right: 5px;
                        "
                        type="button"
                        @click="doPayment('monobank-acquiring', item.id._id)"
                      >
                        <SmallLoader
                          :type="'dark'"
                          v-if="
                            isLoadingPayment &&
                            currentPayment === 'monobank-acquiring'
                          "
                        ></SmallLoader>
                        <template v-else>
                          <span class="btn-inner--icon"></span>
                          <span class="btn-inner--text"> plata by mono</span>
                        </template>
                      </button>
                    </div>
                    <div class="col-lg-2" v-if="branch.paymentLiqPayEnabled">
                      <button
                        class="btn btn-icon btn-3 btn-outline-primary"
                        :disabled="
                          isLoadingPayment ||
                          isInvalidPartnerPaymentAmount(item.id._id)
                        "
                        style="
                          width: 110px;
                          padding-left: 5px;
                          padding-right: 5px;
                        "
                        type="button"
                        @click="doPayment('liqpay-acquiring', item.id._id)"
                      >
                        <SmallLoader
                          :type="'dark'"
                          v-if="
                            isLoadingPayment &&
                            currentPayment === 'liqpay-acquiring'
                          "
                        ></SmallLoader>
                        <template v-else>
                          <span class="btn-inner--icon"
                            ><i class="material-icons">receipt_long</i></span
                          >
                          <span class="btn-inner--text">LiqPay</span>
                        </template>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="row"
              v-if="
                orderTemplate.discounts?.length ||
                orderTemplate.payments?.length ||
                orderTemplate.partnerPayments
              "
            >
              <label>Всі транзакції {{ item.id.name }}</label>
              <div class="table-responsive">
                <table class="table table-hover" id="datatable-search2">
                  <thead class="thead-light">
                    <tr>
                      <th
                        class="text-uppercase text-xxs font-weight-bolder tablepayment"
                      >
                        Дата і час
                      </th>
                      <th
                        class="text-uppercase text-xxs font-weight-bolder tablepayment"
                      >
                        Транзакція
                      </th>
                      <th
                        class="text-uppercase text-xxs font-weight-bolder tablepayment"
                      >
                        Автор
                      </th>
                      <th
                        class="text-uppercase text-xxs font-weight-bolder tablepayment"
                      >
                        Сума
                      </th>
                      <th
                        class="text-uppercase text-xxs font-weight-bolder tablepayment"
                      >
                        Статус
                      </th>
                      <th
                        class="text-uppercase text-xxs font-weight-bolder tablepayment"
                      >
                        Посилання
                      </th>
                      <th
                        class="text-uppercase text-xxs font-weight-bolder tablepayment"
                      >
                        Дія
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(payment, indexPayment) in getPartnerPayments(
                        item.id._id,
                      )"
                      :key="indexPayment"
                      :class="{
                        'slightly-red-bg': payment.operationType === 'refund',
                      }"
                    >
                      <td class="text-xs font-weight-normal tablepayment">
                        <span>{{ showDateTime(payment.createdAt) }}</span>
                      </td>
                      <td class="text-xs font-weight-normal tablepayment">
                        <span v-if="payment.operationType === 'cash'"
                          >Оплата готівка</span
                        >
                        <span
                          v-else-if="payment.operationType === 'card-transfer'"
                          >Оплата картка</span
                        >
                        <span
                          v-else-if="
                            payment.operationType === 'monobank-acquiring'
                          "
                          >Оплата онлайн</span
                        >
                        <span
                          v-else-if="
                            payment.operationType === 'liqpay-acquiring'
                          "
                          >Оплата онлайн</span
                        >
                        <span v-else-if="payment.operationType === 'refund'"
                          >Повернення оплати</span
                        >
                      </td>
                      <td class="text-xs font-weight-normal tablepayment">
                        <span v-if="payment.createdSource === 'clientWebForm'"
                          >Клієнт</span
                        >
                        <span v-else
                          >{{ payment.createdBy?.firstName }}
                          {{ payment.createdBy?.lastName }}</span
                        >
                      </td>
                      <td class="text-xs font-weight-normal tablepayment">
                        <span>{{ payment.amount }} грн</span>
                      </td>
                      <td class="text-xs font-weight-normal">
                        <span
                          class="badge badge-dot"
                          v-if="payment.status === 'pending'"
                        >
                          <i class="bg-info"></i>
                          <span class="text-dark text-xs">Очікує</span>
                        </span>
                        <span
                          class="badge badge-dot"
                          v-else-if="payment.status === 'success'"
                        >
                          <i class="bg-success"></i>
                          <span
                            class="text-dark text-xs"
                            v-if="payment.operationType === 'refund'"
                            >Повернуто</span
                          >
                          <span class="text-dark text-xs" v-else>Сплачено</span>
                          <span class="text-dark text-xs" v-if="payment.paidAt">
                            <br />
                            {{ showDateTime(payment.paidAt) }}
                          </span>
                        </span>
                        <span
                          class="badge badge-dot"
                          v-else-if="payment.status === 'reversed'"
                        >
                          <i class="bg-success"></i>
                          <span class="text-dark text-xs"
                            >Сплачено (+ refund)</span
                          >
                          <span class="text-dark text-xs" v-if="payment.paidAt">
                            <br />
                            {{ showDateTime(payment.paidAt) }}
                          </span>
                        </span>
                        <span
                          class="badge badge-dot"
                          v-else-if="payment.status === 'failure'"
                        >
                          <i class="bg-warning"></i>
                          <span class="text-dark text-xs">Помилка</span>
                        </span>
                        <span
                          class="badge badge-dot"
                          v-else-if="payment.status === 'cancelled'"
                        >
                          <i class="bg-warning"></i>
                          <span class="text-dark text-xs">Відмінено</span>
                        </span>
                        <span
                          class="badge badge-dot"
                          v-else-if="payment.status === 'expired'"
                        >
                          <i class="bg-warning"></i>
                          <span class="text-dark text-xs">Прострочено</span>
                        </span>
                      </td>
                      <td class="text-xs font-weight-normal tablepayment">
                        <div v-if="payment.monobankInvoice?.invoiceId">
                          <span>
                            <a :href="payment.monobankInvoice?.pageUrl">
                              {{ payment.monobankInvoice?.pageUrl }}
                            </a>
                          </span>
                          <i
                            class="material-icons text-md ms-2 me-2 cursor-pointer"
                            @click="
                              copyToClipboard(payment.monobankInvoice?.pageUrl)
                            "
                          >
                            copy
                          </i>
                        </div>
                        <div v-if="payment.liqPayInvoice?.invoiceId">
                          <span>
                            <a :href="payment.liqPayInvoice?.pageUrl">
                              {{ payment.liqPayInvoice?.pageUrl }}
                            </a>
                          </span>
                          <i
                            class="material-icons text-md ms-2 me-2 cursor-pointer"
                            @click="
                              copyToClipboard(payment.liqPayInvoice?.pageUrl)
                            "
                          >
                            copy
                          </i>
                        </div>
                      </td>
                      <td class="text-xs font-weight-normal tablepayment">
                        <div class="btn-group gap-3">
                          <span
                            v-if="
                              payment.monobankInvoice?.invoiceId &&
                              !['success', 'reversed'].includes(payment.status)
                            "
                            @click="
                              selectedQrUrl = payment.monobankInvoice?.pageUrl
                            "
                            data-bs-toggle="modal"
                            :data-bs-target="`#${modalParamsQrCode.modalId}`"
                            class="d-inline-flex cursor-pointer"
                          >
                            <i class="material-icons" style="color: black">
                              qr_code_scanner</i
                            >
                          </span>

                          <span
                            v-if="
                              payment.liqPayInvoice?.invoiceId &&
                              !['success', 'reversed'].includes(payment.status)
                            "
                            @click="
                              selectedQrUrl = payment.liqPayInvoice?.pageUrl
                            "
                            data-bs-toggle="modal"
                            :data-bs-target="`#${modalParamsQrCode.modalId}`"
                            class="d-inline-flex cursor-pointer"
                          >
                            <i class="material-icons"> qr_code_scanner</i>
                          </span>

                          <div
                            class="d-inline-flex align-items-center ms-2 justify-content-center"
                            data-bs-toggle="modal"
                            :data-bs-target="`#${modalParamsInvoiceSms.modalId}`"
                            v-if="isAllowedToInvoiceSms(payment)"
                            @click="
                              showInvoiceModal(
                                this.orderTemplate._id,
                                this.orderTemplate.clientId?.phoneNumber,
                                payment,
                              )
                            "
                          >
                            <i
                              class="material-icons text-lg me-2 cursor-pointer"
                              style="color: #4ea0dc"
                            >
                              send
                            </i>
                          </div>
                          <div
                            class="d-inline-flex align-items-center justify-content-center"
                            v-if="isAllowedDeletePayment(payment)"
                          >
                            <i
                              class="material-icons text-lg me-2 cursor-pointer"
                              data-bs-toggle="modal"
                              :data-bs-target="`#${modalParamsRemovePaymentTransaction.modalId}`"
                              style="color: red"
                              @click="
                                removePaymentTransactionPreHook(
                                  payment._id,
                                  item.id._id,
                                )
                              "
                            >
                              delete
                            </i>
                          </div>
                          <div
                            class="d-inline-flex align-items-center justify-content-center"
                            v-if="isAllowedRefundPayment(payment)"
                          >
                            <i
                              class="material-icons text-lg me-2 cursor-pointer"
                              style="color: orange"
                              data-bs-toggle="modal"
                              :data-bs-target="`#${modalParamsRefundPaymentTransaction.modalId}`"
                              @click="
                                refundPaymentTransactionPreHook(
                                  payment._id,
                                  item.id._id,
                                  payment.amount,
                                )
                              "
                            >
                              keyboard_return
                            </i>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>

          <!-- todo: придумати куди перенести шаблон -->
          <!-- <div class="row" v-if="showDevFeatures">
            <div class="col-3 mt-2">
              <h6>Шаблон для інформування клієнтів</h6>
            </div>
            <div class="col-lg-9 mt-3">
              <div class="row" v-if="showDevFeatures">
                <div class="col-lg-9">
                  <div class="row">
                    <div class="w-90">
                      <div class="input-group input-group-outline">
                        <input
                          type="text"
                          class="form-control"
                          value="Вітаємо! Дякуємо за бронювання. Щоб підтвердити бронювання, відправляємо вам посилання на оплату: 3123124281475128"
                        />
                      </div>
                    </div>
                    <div class="w-10">
                      <div class="col-md-2 mt-2">
                        <i class="material-icons text-lg me-2 cursor-pointer"
                          >copy</i
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-2">
                <div class="col-lg-3 span text-sm">
                  <div class="d-flex justify-content-center mb-1">
                    <div class="col-12">
                      <button class="btn btn-success px-3 mb-0" type="button">
                        Зберегти
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr /> -->
        </div>
      </div>
      <!-- Оплата чека POSter finish-->

      <!-- Зміна кольору івенту -->
      <div class="card mt-2" id="order" v-show="!orderTemplate.externalId">
        <div class="card-body">
          <div class="row mt-2">
            <div class="col-lg-3 mb-2">
              <h6 v-if="company.crmMode === 'hotel' && isTabletOrDesktop">
                Колір бронювання
              </h6>
              <h3 v-if="company.crmMode === 'hotel' && isMobile">
                Колір бронювання
              </h3>

              <h6 v-if="company.crmMode !== 'hotel' && isTabletOrDesktop">
                Колір запису
              </h6>
              <h3 v-if="company.crmMode !== 'hotel' && isMobile">
                Колір запису
              </h3>
            </div>
            <div class="col-lg-9">
              <div class="row mt-1">
                <ColorRadioButtons
                  v-model="orderTemplate.color"
                  :colors="colorsOrderList"
                  :with-empty-color="true"
                ></ColorRadioButtons>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Finish зміна кольору івенту -->

      <!-- Comments for not personal-->
      <div class="card mt-2" id="comments" v-if="!isPersonal">
        <div class="card-body">
          <h6 v-if="isTabletOrDesktop">{{ getCommentLabel() }}</h6>
          <h3 v-if="isMobile">{{ getCommentLabel() }}</h3>

          <div class="col-12">
            <div
              class="input-group input-group-outline"
              :class="{ 'is-filled': orderTemplate.comment }"
            >
              <textarea
                type="text"
                rows="2"
                class="form-control"
                v-model="orderTemplate.comment"
                @input="autoResizeTextarea"
                style="height: 75px"
                ref="description"
                :placeholder="getCommentPlaceholder()"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- Comments finish -->

      <!-- Сповіщення -->
      <div
        class="card mt-2"
        id="notifications"
        v-if="!isPersonal && !orderTemplate.externalId"
      >
        <div class="card-body">
          <div class="row">
            <div class="col-10" v-if="company.crmMode !== 'hotel'">
              <h6 v-if="isTabletOrDesktop">SMS сповіщення про запис</h6>
              <h3 v-if="isMobile">SMS сповіщення про запис</h3>
            </div>
            <div class="col-10" v-if="company.crmMode === 'hotel'">
              <h6 v-if="isTabletOrDesktop">SMS про створення броні</h6>
              <h3 v-if="isMobile">SMS про створення броні</h3>
            </div>
            <div class="col-2 form-check form-switch text-end mt-2">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="orderTemplate.smsNotifyNewServiceOrder"
                :disabled="mode === 'edit'"
              />
            </div>
          </div>
          <div class="col-12">
            <div class="col-lg-12 col-lg-6" v-if="company.crmMode === 'hotel'">
              Відправити SMS сповіщення клієнту про створене бронювання.
              <p>
                SMS буде надіслано одразу після створення бронювання за умови,
                що додано клієнта з номером телефону.
              </p>
            </div>
            <div class="col-lg-12 col-lg-6" v-if="company.crmMode !== 'hotel'">
              Відправити SMS сповіщення клієнту про створений запис.
              <p>
                SMS буде надіслано одразу після створення запису за умови, що
                додано клієнта з номером телефону.
              </p>
            </div>
          </div>
        </div>
      </div>
      <!-- Сповіщення finish -->

      <!-- Communication -->
      <div
        class="card mt-2"
        id="notifications"
        v-if="!isPersonal && !orderTemplate.externalId"
      >
        <div class="card-body">
          <div class="row">
            <h6 v-if="isTabletOrDesktop">SMS Повідомлення</h6>
            <h3 v-if="isMobile">SMS Повідомлення</h3>
            <hr />
            <div class="card-body overflow-auto overflow-x-hidden">
              <div v-if="instantMessages.length === 0">
                <div class="row">
                  <div class="row justify-content-center text-center">
                    <span>Тут будуть всі надіслані SMS</span>
                  </div>
                  <div class="row justify-content-center text-center">
                    <a href="/integrations/sms-settings">Налаштувати SMS</a>
                    <a href="/integrations/sms-payment">Поповнити рахунок</a>
                  </div>
                </div>
              </div>
              <div
                v-for="message in instantMessages"
                v-bind:key="message.messageId"
              >
                <div class="row mb-2">
                  <div class="col-md-12 text-center">
                    <div class="col-md-12 text-center">
                      <span class="badge text-dark">{{
                        getMessageDate(message.startTime)
                      }}</span>
                    </div>

                    <span
                      v-if="message.automatic"
                      class="badge badge-sm badge-info"
                      >автоматичне: {{ message.automatic }}</span
                    >
                    <span v-if="!message.automatic" class="text-xs"
                      >Відправник:
                      {{
                        message.createdBy.firstName +
                        " " +
                        message.createdBy.lastName
                      }}</span
                    >
                  </div>
                </div>
                <div class="row justify-content-end text-right mb-4">
                  <div class="col-auto">
                    <div class="card customCard bg-gradient-secondary">
                      <div class="card-body py-2 px-3 text-white">
                        <p class="mb-0">{{ message.text }}</p>
                        <div
                          class="d-flex align-items-center justify-content-end text-sm opacity-6"
                        >
                          <span
                            :class="getMessageStatusClass(message.status)"
                            >{{ getMessageStatus(message.status) }}</span
                          >
                          <i
                            v-if="
                              message.status === 'Sent' ||
                              message.status === 'Delivered' ||
                              message.status === 'Read'
                            "
                            class="ni ni-check-bold text-sm me-1"
                          />
                          <i
                            v-if="
                              message.status === 'Delivered' ||
                              message.status === 'Read'
                            "
                            class="ni ni-check-bold text-sm me-1"
                            style="margin-left: -10px"
                          /><small class="mx-2">{{
                            getMessageTime(message.startTime)
                          }}</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr />
            <div class="row mt-2 pe-0">
              <div class="input-group input-group-outline pe-0">
                <textarea
                  class="form-control"
                  style="height: 75px"
                  v-model="instantMessageText"
                  placeholder="Введіть текст СМС"
                  ref="description"
                  rows="3"
                  maxlength="661"
                  @input="autoResizeTextarea"
                ></textarea>
              </div>
            </div>

            <div class="row mt-2 pe-0">
              <div class="col-4 text-start">
                <div style="font-size: 10px">Баланс SMS: {{ smsBalance }}</div>
                <div style="font-size: 10px">
                  Кількість SMS:
                  {{ getInstantMessagePrice(instantMessageText) }}
                </div>
              </div>
              <div class="col-8 text-end pe-0">
                <button
                  class="btn bg-gradient-sms-color mb-0"
                  :disabled="!smsBalance"
                  @click="sendCustomInstantMessage"
                >
                  Надіслати SMS <i class="material-icons">send</i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Communication finish -->

      <!-- Card History -->
      <div
        class="card mt-2"
        id="history"
        v-if="
          mode === 'edit' &&
          orderTemplate?.history?.length > 0 &&
          !orderTemplate.externalId
        "
      >
        <div class="card-header">
          <h6 v-if="isTabletOrDesktop">Історія</h6>
          <h3 v-if="isMobile">Історія</h3>
        </div>
        <div class="card-body pt-0">
          <div class="col-9">
            <div class="timeline timeline-one-side">
              <div
                class="timeline-block mb-3"
                v-for="(entry, index) in orderTemplate.history"
                :key="index"
              >
                <span class="timeline-step">
                  <i class="material-icons text-secondary text-lg">edit_note</i>
                  <!--<i class="material-icons text-secondary text-lg">paid</i>-->
                  <!--<i class="material-icons text-secondary text-lg">done</i>-->
                  <!--<i class="material-icons text-secondary text-lg">free_cancellation</i>-->
                  <!--<i class="material-icons text-secondary text-lg">check_circle_outline</i>-->
                </span>
                <div class="timeline-content">
                  <h6 class="text-dark text-sm font-weight-bold mb-0">
                    {{ entry.subject }}
                  </h6>
                  <p
                    class="text-secondary font-weight-normal text-xs mt-1 mb-0"
                  >
                    {{ entry.description }}
                  </p>
                  <p
                    class="text-secondary font-weight-normal text-xs mt-1 mb-0"
                  >
                    {{ datetimeToString(entry.createdAt) }}
                    <span
                      v-if="
                        entry.createdBy?.firstName || entry.createdBy?.lastName
                      "
                    >
                      ({{ entry.createdBy?.firstName }}
                      {{ entry.createdBy?.lastName }})
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Card History finish -->

      <!-- Card Удалить услугу -->
      <div
        class="card mt-2 customMargin"
        id="delete"
        v-if="this.orderTemplate._id && isPersonal"
      >
        <div class="card-body">
          <div class="d-flex align-items-center">
            <div class="row">
              <h3 v-if="company.crmMode === 'hotel' && isMobile">
                Видалити бронь
              </h3>
              <h3 v-if="company.crmMode !== 'hotel' && isMobile">
                Видалити запис
              </h3>

              <h6 v-if="company.crmMode === 'hotel' && isTabletOrDesktop">
                Видалити бронь
              </h6>
              <h6 v-if="company.crmMode !== 'hotel' && isTabletOrDesktop">
                Видалити запис
              </h6>

              <p v-if="company.crmMode === 'hotel'">
                Видаливши закриті дати вони стануть доступні для бронювання
              </p>
              <p v-if="company.crmMode !== 'hotel'">
                Видаливши особисту подію цей час стане вільним для запису
              </p>
            </div>
            <div class="w-50 text-end">
              <button
                class="btn bg-gradient-danger mb-0 ms-2"
                type="button"
                name="button"
                data-bs-toggle="modal"
                :data-bs-target="`#${modalParamsRemoveServiceOrder.modalId}`"
                :disabled="!preDeleteValidation()"
              >
                Видалити
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        class="card mt-4 customMargin"
        id="delete"
        v-if="isAllowedDeleteServiceOrder() && !isPersonal"
      >
        <div class="badge bg-gradient-warning">Тільки для власників</div>
        <div class="card-body">
          <div class="d-flex align-items-center mb-sm-0 mb-4">
            <div class="row">
              <h3 v-if="company.crmMode === 'hotel' && isMobile">
                Видалити бронь
              </h3>
              <h3 v-if="company.crmMode !== 'hotel' && isMobile">
                Видалити запис
              </h3>

              <h6 v-if="company.crmMode === 'hotel' && isTabletOrDesktop">
                Видалити бронь
              </h6>
              <h6 v-if="company.crmMode !== 'hotel' && isTabletOrDesktop">
                Видалити запис
              </h6>

              <p v-if="company.crmMode === 'hotel'">
                Бронювання буде видалено без можливості його відновити
              </p>
              <p v-if="company.crmMode !== 'hotel'">
                Запис буде видалено без можливості його відновити
              </p>
            </div>
            <div class="w-50 text-end">
              <button
                class="btn bg-gradient-danger mb-0 ms-2"
                type="button"
                name="button"
                data-bs-toggle="modal"
                :data-bs-target="`#${modalParamsRemoveServiceOrder.modalId}`"
              >
                Видалити
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- Удалить услугу finish -->

      <!-- Оплата групової послуги finish -->
      <!-- <div
        class="card mt-4"
        id="order"
        v-if="showDevFeatures && !isPersonal && company.crmMode !== 'hotel'"
      >
        <div class="badge bg-gradient-info">DEV</div>
        <div class="card-body">
          <div class="row">
            <div class="col-3 mt-2">
              <h6>Оплата</h6>
            </div>
            <div class="col-lg-9 mt-2">
              <div class="row">
                <div class="col-lg-3 text-xs">Клієнт: Павлов Артем</div>
                <div class="col-lg-3">
                  <span class="text-xs">абонементу нема, або закінчився </span>
                  <button
                    class="btn btn-sm btn-outline-dark mb-0"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#button-add-new-client"
                  >
                    + абонемент
                  </button>


                  <div
                    class="modal fade"
                    id="modal-button-add-new-client"
                    tabindex="-1"
                    role="dialog"
                    aria-labelledby="exampleModalLabel-2"
                    aria-hidden="true"
                  >
                    <div
                      class="modal-dialog modal-dialog-centered"
                      role="document"
                    >
                      <div class="modal-content">
                        <div class="modal-header">
                          <h5
                            class="modal-title font-weight-normal"
                            id="exampleModalLabel-2"
                          >
                            Продаж абонементу
                          </h5>
                          <button
                            type="button"
                            class="btn-close text-dark"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <span aria-hidden="true">&times;</span>
                          </button>
                        </div>
                        <div class="modal-body">
                          <div class="row">
                            <div class="w-50">Клієнт <b>Павлов Артем</b></div>
                            <div class="w-50"></div>
                          </div>
                          <div class="row">
                            <div class="col-lg-6">Оберіть абонменет</div>
                            <div class="col-lg-6">
                              <div class="dropdown d-inline">
                                <a
                                  href="javascript:"
                                  class="btn btn-sm btn-outline-dark dropdown-toggle"
                                  data-bs-toggle="dropdown"
                                  id="navbarDropdownMenuLink2"
                                >
                                  Вибрати
                                </a>
                                <ul
                                  class="dropdown-menu dropdown-menu-lg-start px-2 py-3"
                                  aria-labelledby="navbarDropdownMenuLink2"
                                  data-popper-placement="left-start"
                                >
                                  <li>
                                    <a
                                      class="dropdown-item border-radius-md"
                                      href="javascript:"
                                      >Гітара группове 12 відвідувань
                                      (3600грн)</a
                                    >
                                  </li>
                                  <li>
                                    <a
                                      class="dropdown-item border-radius-md"
                                      href="javascript:"
                                      >Гітара группове 8 відвідувань
                                      (2400грн)</a
                                    >
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                          Оплата
                          <div class="col-lg-4">
                            <div
                              class="input-group input-group-outline paymentInput"
                            >
                              <input
                                type="email"
                                class="form-control"
                                placeholder="Готівка. Сума грн."
                              />
                            </div>
                            <div
                              class="input-group input-group-outline paymentInput mt-1"
                            >
                              <input
                                type="email"
                                class="form-control"
                                placeholder="Картка. Сума грн."
                              />
                            </div>
                            <div
                              class="input-group input-group-outline paymentInput mt-1"
                            >
                              <input
                                type="email"
                                class="form-control"
                                placeholder="Інший спосіб. Сума грн."
                              />
                            </div>
                            <div
                              class="d-flex justify-content-center mb-1"
                              v-if="showDevFeatures"
                            ></div>
                          </div>
                        </div>
                        <div class="modal-footer">
                          <button
                            type="button"
                            class="btn bg-gradient-secondary"
                            data-bs-dismiss="modal"
                          >
                            закрити
                          </button>

                          <button
                            type="button"
                            class="btn bg-gradient-primary"
                            data-bs-dismiss="modal"
                          >
                            зберегти
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3">
                  <div class="input-group input-group-outline paymentInput">
                    <input
                      type="email"
                      class="form-control text-xs"
                      placeholder="Готівка. Сума грн."
                    />
                  </div>
                  <div
                    class="input-group input-group-outline paymentInput mt-1"
                  >
                    <input
                      type="email"
                      class="form-control text-xs"
                      placeholder="Картка. Сума грн."
                    />
                  </div>
                  <div
                    class="input-group input-group-outline paymentInput mt-1"
                  >
                    <input
                      type="email"
                      class="form-control text-xs"
                      placeholder="Інший спосіб. Сума грн."
                    />
                  </div>

                  <div
                    class="d-flex justify-content-center mb-1"
                    v-if="showDevFeatures"
                  >
                    <div class="col-12">
                      <button class="btn btn-success px-3 mb-0" type="button">
                        Зберегти
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <hr />
            </div>
          </div>
        </div>
      </div> -->
      <!-- Оплата групової послуги finish -->

      <!--  Save Account -->
      <div class="fixed-bottom">
        <div class="row tablet-button">
          <div class="col-10"></div>
          <div class="col-lg-2">
            <div class="mx-4">
              <button
                type="button"
                class="block btn bg-gradient-primary mb-3 saveOrderButton"
                @click="save"
                :disabled="!preSaveValidation() || isSaveActive"
              >
                <SmallLoader v-if="isSaveActive" />
                <span v-else> Зберегти</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!--  Save Account finish-->
    </div>
  </div>

  <BmnCustomPopup :params="modalParamsRemoveServiceOrder"></BmnCustomPopup>
  <BmnCustomPopup
    :params="modalParamsRemoveDiscountTransaction"
  ></BmnCustomPopup>
  <BmnCustomPopup
    :params="modalParamsRemovePaymentTransaction"
  ></BmnCustomPopup>
  <BmnCustomPopup :params="modalParamsRefundPaymentTransaction">
    <template #modal-default>
      <div class="col-lg-3">
        <label>Сума грн.</label>
        <div class="input-group input-group-outline paymentInput">
          <input
            type="number"
            class="form-control text-xs"
            v-model="modalParamsRefundPaymentTransaction.data.amount"
          />
        </div>
      </div>
    </template>
  </BmnCustomPopup>
  <BmnCustomPopup :params="modalParamsQrCode">
    <template #modal-qr>
      <qr-code
        v-if="selectedQrUrl"
        error-level="M"
        :text="selectedQrUrl"
      ></qr-code>
    </template>
  </BmnCustomPopup>
  <BmnCustomPopup ref="modalInvoiceSms" :params="modalParamsInvoiceSms">
    <template #modal-default>
      <div class="modal-body">
        <div class="row">
          <label class="text-md"> Телефон отримувача </label>
        </div>
        <div class="row">
          <div
            class="input-group input-group-lg input-group-outline"
            :class="{
              'is-filled': modalParamsInvoiceSms.data?.phoneNumber,
            }"
          >
            <input
              class="form-control form-control-lg"
              v-mask="'+380#########'"
              maxlength="13"
              minlength="13"
              pattern="\+[0-9]{12}"
              placeholder="+380"
              type="tel"
              v-model="modalParamsInvoiceSms.data.phoneNumber"
            />
          </div>
        </div>
        <div class="row mt-3">
          <div class="mt-2">
            <label class="text-md">Текст повідомлення</label>
            <i
              class="material-icons text-md ms-2 me-2 cursor-pointer"
              @click="copyTextFromModal(modalParamsInvoiceSms.data.text)"
            >
              copy
            </i>
          </div>
        </div>
        <div class="row">
          <div
            class="input-group input-group-outline"
            :class="{
              'is-filled': modalParamsInvoiceSms.data?.text,
            }"
          >
            <textarea
              type="text"
              rows="2"
              class="form-control"
              v-model="modalParamsInvoiceSms.data.text"
              @input="autoResizeTextarea"
              style="height: 75px"
              ref="description"
            />
          </div>
        </div>
        <div class="mt-2">
          <span class="text-xs">
            <a :href="this.modalParamsInvoiceSms.data.paymentUrl">
              {{ this.modalParamsInvoiceSms.data.paymentUrl }}
            </a>
          </span>
          <i
            class="material-icons text-md ms-2 me-2 cursor-pointer"
            @click="
              copyTextFromModal(
                this.modalParamsInvoiceSms.data.paymentUrl?.toString(),
              )
            "
          >
            copy
          </i>
        </div>
      </div>
    </template>
  </BmnCustomPopup>
</template>

<script src="./NewServiceOrder.js"></script>

<style scoped>
.disabled-decrease-opacity {
  opacity: 0.1 !important;
}

.max-width-content {
  max-width: max-content;
}
.disabled-select {
  pointer-events: none;
  opacity: 0.5;
}
.custom-form-label {
  margin-top: 8px;
  margin-bottom: 0px;
}
.custom-form-check {
  padding-top: 8px;
  padding-left: 4px;
}
.custom-control-label {
  padding-left: 5px;
}
table td {
  vertical-align: middle; /* Выравнивание по вертикали */
}

@media only screen and (min-width: 768px) and (max-width: 1500px) {
  .tablet-button {
    margin-bottom: 25px; /* Задайте нужный отступ от низа */
  }
}

.tablepayment {
  padding: 0.75rem;
}

.freepayment {
  padding-left: 0em !important;
}

.block {
  display: block;
  width: 100%;
  margin-bottom: 0.5rem;
}

.flatpickr-calendar .flatpickr-day.today {
  background: red !important;
  color: #000000 !important;
  border: #0d6efd 5px !important;
}

.nav-tabs .nav-link {
  width: 33%;
}

.paymentInput {
  text-align: center;
}

.badge.label-personal {
  background-image: none;
  background-color: #fbe6cf !important;
  color: grey;
}

.datetimepicker.flatpickr-input.bmn-calendar-input {
  text-align: center;
  display: none;
}

.error-message {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
  background-image: none;
}

.confirmed {
  display: flex;
  align-items: center; /* Выравниваем элементы по центру по вертикали */
}
.element {
  padding-left: 16px;
  margin-right: 10px;
}
.customBtn {
  color: white;
}
.overbookingAlert {
  color: white;
}
.personalSwitcher {
  display: flex;
  align-items: center; /* Выравниваем элементы по центру по вертикали */
  justify-content: flex-start;
  padding-right: 16px;
}

.customHeight {
  height: 120px !important;
}

.status-success-color {
  color: #458466;
}
.text-status-color {
  color: black !important;
}

.nav-tabs .nav-link.active {
  border-color: #0f4432 #0f4432 #fff;
}
.custom-control-label-text {
  padding-top: 1px;
  padding-left: 4px;
}

.bg-gradient-sms-color {
  background-color: #458466;
  color: white;
}
.customMargin {
  margin-bottom: 48px;
}

#datatable-search2 {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #ddd;
}

#datatable-search2 th,
#datatable-search2 td {
  border-bottom: 1px solid #ddd;
}

#datatable-search2 th,
#datatable-search2 td {
  border-right: 1px solid #ddd;
}

#datatable-search2 th:first-child,
#datatable-search2 td:first-child {
  border-left: 1px solid #ddd;
}

#datatable-search2 th {
  border-top: 1px solid #ddd;
}

#datatable-search2 {
  border-collapse: collapse;
}
.container-fluid {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}
.card {
  border-radius: 0px;
}
.customCard {
  border-radius: 16px !important;
}
.nav-wrapper {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
</style>
